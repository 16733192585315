import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

interface ProductProps {
  small_image: any
  hover_image: string
  filters: any
  variants: any
  configurable_options: any
}

export const useProductItem = ({
  small_image,
  hover_image,
  filters,
  variants,
  configurable_options
}: ProductProps) => {
  const storeConfig = useSelector((state: any) => state.app.storeConfig)

  const [find, setFind] = useState<any>(null)
  const [addToCartDialogOpen, setAddToCartDialogOpen] = useState<boolean>(false)
  const [showAddToCartButton, setShowAddToCartButton] = useState<boolean>(false)

  const [images, setImages] = useState<any>({
    small: find?.small_image || small_image,
    hover: find?.hover_image || hover_image
  })

  useEffect(() => {
    const colorOptions =
      configurable_options?.find((temp: any) => temp.attribute_code === 'color')
        ?.values || []
    if (filters) {
      filters.forEach((filter: any) => {
        if (filter.aggregation.attribute_code === 'color') {
          const selectedUid = colorOptions.find(
            (temp: any) => temp.label === filter.option.label
          )?.uid
          const find2 = variants.find((temp: any) => {
            return temp.attributes.some(
              (attr: any) => attr.code === 'color' && attr.uid === selectedUid
            )
          })?.product
          setFind(find2)
        }
      })
    }
  }, [configurable_options, filters, variants])

  useEffect(() => {
    if (find) {
      setImages({
        small: find.small_image,
        hover: find.hover_image
      })
    }
  }, [find])

  const suffix: string = useMemo(() => {
    return storeConfig?.product_url_suffix ?? ''
  }, [storeConfig])

  const handleSelect = useCallback((params: any) => {
    setImages({ ...params })
  }, [])

  const handleOpenAddToCartDialog = useCallback(() => {
    setAddToCartDialogOpen(true)
  }, [])

  const handleCloseAddToCartDialog = () => {
    setAddToCartDialogOpen(false)
  }

  return {
    images,
    suffix,
    handleSelect,
    addToCartDialogOpen,
    setAddToCartDialogOpen,
    handleOpenAddToCartDialog,
    handleCloseAddToCartDialog,
    showAddToCartButton,
    setShowAddToCartButton
  }
}
