import { FC, memo, useCallback } from 'react'
import { X } from 'react-feather'

import { Icon, I18n } from '@headless/ui'

import { StyledFilterButton } from './styled'

interface CurrentFilterProps {
  group: string
  label: string
  item: any
  removeItem: Function
  setIsApplying: Function
}

const CurrentFilter: FC<CurrentFilterProps> = ({
  group,
  item,
  removeItem,
  setIsApplying
}) => {
  const handleClick = useCallback(() => {
    removeItem({ group, item })
    setIsApplying(true)
  }, [removeItem, group, item, setIsApplying])

  return (
    <StyledFilterButton
      endIcon={<Icon src={X} size={16} />}
      onClick={handleClick}
    >
      {item.value === '0' ? (
        <span>
          <I18n id={`global.${item.value === '0' ? 'yes' : 'no'}`} />
        </span>
      ) : (
        <span dangerouslySetInnerHTML={{ __html: item.label }} />
      )}
    </StyledFilterButton>
  )
}

export default memo(CurrentFilter)
