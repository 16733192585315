import styled from '@emotion/styled'

export const StyledProductList = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(
      0,
      1fr
    );
  grid-column-gap: 1.875rem;
  grid-row-gap: 1.875rem;

  @media screen and (max-width: 767px) {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-column-gap: 0.9375rem;
  }
`

export const StyledPagination = styled.div`
  display: flex;
  margin-top: 2.5rem;
  justify-content: flex-end;
`
