import styled from '@emotion/styled'

export const StyledContainer = styled.div`
  padding: 0.2rem 0;
`

export const StyledText = styled.span`
  cursor: pointer;
  display: inline-block;
  font-size: 0.875rem;
  color: #615956;

  &:hover {
    text-decoration: underline;
  }

  a {
    color: #615956;
    text-decoration: none;
  }
`
