import styled from '@emotion/styled'

export const StyledCollapse = styled.div`
  border-bottom: 1px solid #eae7e4;

  .tab {
    &__title {
      padding: 1rem 20px 1rem 0;
      margin-bottom: 0;
      color: #222;
      font-size: 1rem;
      font-weight: 600;
      cursor: pointer;
      position: relative;

      &:before {
        position: absolute;
        right: 12px;
        top: 50%;
        margin-top: -8px;
        content: '';
        height: 16px;
        width: 2px;
        background-color: #1b1e22;
        transition: all 0.3s;
      }

      &:after {
        position: absolute;
        right: 5px;
        top: 50%;
        margin-top: -1px;
        content: '';
        width: 16px;
        height: 2px;
        background-color: #1b1e22;
      }

      &.active {
        &:before {
          transform: rotate(90deg);
        }
      }
    }
  }

  .MuiCollapse-root {
    .MuiButtonBase-root {
      padding: 0.5rem 0.9375rem 0.5rem 0.9375rem;
      border-color: #d6d0cb;
      justify-content: flex-start;

      &:hover {
        border-color: #212529;
        background-color: transparent;
      }

      &.MuiButton-containedSuccess {
        border-color: #d6d0cb;
        background-color: #1b5e20;
      }
    }
  }

  .MuiCollapse-wrapperInner {
    > div {
      > div:last-of-type {
        margin-bottom: 1.5rem;
      }
    }
  }

  @media screen and (max-width: 767px) {
    border-bottom: 0;

    .tab {
      &__title {
        padding: 0.9375rem;
        font-size: 1.125rem;
        font-weight: 500;
        text-transform: uppercase;
        border: 1px solid #615956;

        &:before {
          display: none;
        }

        &:after {
          top: 1.125rem;
          right: 0.9375rem;
          display: inline-block;
          width: 0.9375rem;
          height: 0.9375rem;
          background: #fff url('/images/gather.svg') no-repeat;
          background-size: 1050%;
          background-position: -23px -316px;
          transition: all 0.3s;
          transform: rotate(90deg);
        }

        &.active {
          &:after {
            transform: rotate(-90deg);
          }
        }
      }
    }

    .MuiCollapse-root {
      .MuiButtonBase-root {
        padding: 0.9375rem;

        span {
          font-size: 1rem;
        }
      }
    }

    .MuiCollapse-wrapperInner {
      > div {
        > div:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
`
