import { useMutation } from '@apollo/client'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import { isEmpty } from 'lodash'
import { useSnackbar } from 'notistack'
import { memo, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import SuccessOrErrorMessage from '@components/successOrErrorMessage'
import { ADD_PRODUCT_TIP } from '@graphql/mutations/addProductTip'
import {
  BaseCheckbox,
  BaseInput,
  Dialog,
  DialogContent,
  DialogTitle,
  InputAdornment,
  LoadingButton
} from '@headless/ui'
import { event } from '@headless/utils'

import { StyledNotify } from './styled'

const CatalogNotify = ({ id, sku }: any) => {
  const { enqueueSnackbar } = useSnackbar()
  const [productTipMutation] = useMutation(ADD_PRODUCT_TIP)
  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit
  } = useForm({
    defaultValues: {
      email: '',
      signup: false
    }
  })
  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [current, setCurrent] = useState<any>(null)
  const [successMessage, setSuccessMessage] = useState<string>('')

  const handleClose = () => {
    setOpen(false)
    setCurrent(null)
  }

  const handleFormSubmit = handleSubmit(async (values: any) => {
    try {
      setLoading(true)
      const { data } = await productTipMutation({
        variables: {
          input: {
            sku: current?.sku ?? sku,
            product_id: id,
            email: values.email
          }
        }
      })

      if (data) {
        await enqueueSnackbar(`You had submitted success!`, {
          variant: 'success'
        })
        setSuccessMessage('You had submitted success!')
        setLoading(false)
      }
    } catch (error) {
      setSuccessMessage('')
      setLoading(false)
    }
  })

  useEffect(() => {
    const handleToggleVisible = (params: any) => {
      setOpen(true)
      setCurrent(params)

      if (!isEmpty(params.option)) {
        setValue(params.option.code, params.option.label)
      }
    }

    event.on('openNotify', handleToggleVisible)

    return () => {
      event.off('openNotify', handleToggleVisible)
    }
  }, [setValue])

  return (
    <Dialog
      className="modal__notify"
      maxWidth={false}
      open={open}
      onClose={handleClose}
    >
      <StyledNotify noValidate onSubmit={handleFormSubmit}>
        <DialogTitle>
          <span>Get On The Waitlist</span>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className="form__item">
            {current && !isEmpty(current.option) && (
              <BaseInput
                name={current.option.code}
                label={current.option.name}
                fullWidth
                required
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <i
                        className="icon"
                        style={{ backgroundImage: `url(${current.image.url})` }}
                      />
                    </InputAdornment>
                  )
                }}
                control={control}
                errors={errors}
              />
            )}
            <BaseInput
              name="email"
              label="Email"
              fullWidth
              required
              control={control}
              errors={errors}
            />
          </div>
          <SuccessOrErrorMessage
            message={successMessage}
            successOrError={1}
            isShow={!!successMessage}
          />
          <p>
            Leave us your email and we&apos;ll notify you as soon as this item
            is available to purchase!
          </p>
          <div className="form__action">
            <LoadingButton
              type="submit"
              variant="contained"
              size="large"
              fullWidth
              loading={loading}
              disabled={loading}
              loadingIndicator="Submiting ..."
            >
              <span>Submit</span>
            </LoadingButton>
          </div>
          <div className="form__checkbox">
            <BaseCheckbox
              name="signup"
              label="Sign up for new arrivals and promotions."
              control={control}
              errors={errors}
              setValue={setValue}
            />
          </div>
        </DialogContent>
      </StyledNotify>
    </Dialog>
  )
}

export default memo(CatalogNotify)
