import styled from '@emotion/styled'

import { Popper } from '@headless/ui'

export const StyledTools = styled.div`
  @media screen and (max-width: 767px) {
    display: grid;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 0 10px;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px;

    .tools {
      display: flex;
      margin-bottom: 0;
      color: #222;
      font-size: 0.75rem;
      font-weight: 500;
      text-align: center;
      text-transform: uppercase;
      justify-content: center;
      align-items: center;
      border: 1px solid #222;

      .icon {
        display: inline-block;
        margin-top: 0.3125rem;
        margin-right: 0.3125rem;
        width: 0.9375rem;
        height: 0.9375rem;
        vertical-align: middle;
        background: #fff url('/images/filters.svg') no-repeat;
      }
    }
  }
`

export const StyledSort = styled.div`
  .category {
    &__sort {
      display: grid;
      grid-auto-flow: column;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 767px) {
    position: relative;

    .MuiPopperUnstyled-root {
      right: 0 !important;
      left: 0 !important;

      .MuiPaper-root {
        border: 1px solid #222;
        border-radius: 0;
      }
    }

    .category {
      &__sort {
        position: relative;
        display: flex;
        padding: 0.35rem 0;
        font-size: 0.75rem;
        text-align: center;
        text-transform: uppercase;
        border: 1px solid #222;
        justify-content: center;
        align-items: center;

        .label {
          display: none;
        }

        svg {
          position: absolute;
          top: 0.125rem;
          right: 0.3125rem;
          width: 1.125rem;
          z-index: 20;
        }
      }
    }

    .MuiMenuItem-root {
      font-size: 0.75rem;
      padding: 0.5rem 1rem;
      min-height: unset;
    }
  }
`

export const StyledPopper = styled(Popper)({
  '&': {
    zIndex: 30
  }
})
