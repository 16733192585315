import styled from '@emotion/styled'

export const StyledFilterBlock = styled.div`
  @media screen and (max-width: 767px) {
    .filter__item {
      .MuiButton-root {
        border-bottom: 0;
      }

      &:first-of-type {
        .MuiButton-root {
          border-top-color: #615956;
        }

        .MuiLink-root {
          border-top-color: #615956;
        }
      }
    }
  }
`
