import styled from '@emotion/styled'

export const StyledProductOptions = styled.div`
  margin-top: 0.9375rem;
`

export const StyledOptionItem = styled.ul`
  display: grid;
  margin-bottom: 0.5rem;
  grid-auto-flow: column;
  grid-column-gap: 0.625rem;
  justify-content: flex-start;
  align-items: center;

  picture {
    display: flex;
    width: 2.625rem;
    height: 2.625rem;
    cursor: pointer;
    border: 1px solid transparent;

    img {
      width: 100%;
    }

    &:hover {
      border-color: #212529;
    }
  }

  > li {
    &.active {
      picture {
        border-color: #212529;
      }
    }
  }

  @media screen and (max-width: 767px) {
    picture {
      margin-top: 0.25rem;
      width: 1.5625rem;
      height: 1.5625rem;
    }
  }
`

export const StyledOption = styled.span`
  display: flex;
  width: 2.5rem;
  height: 2.5rem;
  font-size: 0.9rem;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;

  &:hover {
    border-color: #212529;
  }
`
