import styled from '@emotion/styled'

export const StyledCategoryDrawer = styled.div`
  padding: 0.9375rem;

  .filters__title {
    font-size: 1rem;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }
`

export const StyledClose = styled.div`
  display: flex;
  justify-content: flex-end;
`
