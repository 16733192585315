import { useCategoryDetail } from '@hooks/CategoryPage'
import { FC, memo, useState } from 'react'
import { StyledDescription } from './styled'

interface CategoryDescriptionProps {
  id: number
}

const CategoryDescription: FC<CategoryDescriptionProps> = ({ id }) => {
  const { category } = useCategoryDetail(id)
  const { description } = category

  const [detailVisible, setDetailVisible] = useState(true)

  const IconClass = detailVisible
    ? 'description__titleIcon'
    : 'description__titleIcon collapsed'

  const maybeDetail = detailVisible ? (
    <div
      className="description__content"
      dangerouslySetInnerHTML={{ __html: description }}
    />
  ) : null

  return (
    <StyledDescription>
      <div
        className="description__title"
        onClick={() => {
          setDetailVisible((prevVisible) => !prevVisible)
        }}
      >
        About {category.name !== 'CROWNS' ? category.name : 'Crowns'}{' '}
        <span className={IconClass} />
      </div>
      {maybeDetail}
    </StyledDescription>
  )
}

export default memo(CategoryDescription)
