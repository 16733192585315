import { FC, memo } from 'react'

import { StyledOptionItem, StyledOption } from './styled'

interface ProductOptionsProps {
  options: any[]
}

const ProductOptions: FC<ProductOptionsProps> = ({ options }) => {
  return (
    <>
      {options.length > 0 && (
        <div className="product__options">
          {options.map((option: any) => {
            const { attribute_code, label, values } = option
            const avliable: any[] = values.slice(0, 3)
            return (
              <div className="option__item" key={attribute_code}>
                {avliable.length > 0 && (
                  <StyledOptionItem>
                    {avliable.map((value: any) => {
                      const { uid, swatch_data } = value
                      const isImage: boolean = !!(swatch_data?.thumbnail ?? '')
                      return (
                        <li key={uid}>
                          {attribute_code === 'color' ? (
                            <StyledOption
                              style={{
                                backgroundColor: isImage
                                  ? 'transparent'
                                  : swatch_data?.value,
                                backgroundImage: isImage
                                  ? `url(${swatch_data.thumbnail})`
                                  : 'unset'
                              }}
                            />
                          ) : (
                            <StyledOption
                              dangerouslySetInnerHTML={{
                                __html: value.label
                              }}
                            />
                          )}
                        </li>
                      )
                    })}
                    <li>
                      <span>{`${values.length} ${label}${
                        values.length > 1 ? 's' : ''
                      }`}</span>
                    </li>
                  </StyledOptionItem>
                )}
              </div>
            )
          })}
        </div>
      )}
    </>
  )
}

export default memo(ProductOptions)
