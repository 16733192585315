import styled from '@emotion/styled'

export const StyledCategoriesAddToCartDialog = styled.div`
  position: fixed;
  width: 1000px;
  height: 460px;
`

export const StyledCatalogMainDialog = styled.div`
  width: 1000px;
  height: 660px;
  padding: 50px;
  display: grid;
  grid-template-columns: 1fr 21.25rem;
  grid-column-gap: 1rem;
  align-items: flex-start;

  .image-gallery-thumbnail {
    width: 76px !important;
    min-height: 101px !important;
  }

  .image-gallery-thumbnails-wrapper {
    width: 76px !important;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 100vh;
    padding: 0;
    padding-top: 46px;
  }

  .product {
    &__main {
      width: 345px;

      @media screen and (max-width: 767px) {
        width: 100%;
        padding: 0 15px;
      }

      .view_full_details {
        margin-top: 20px;
        display: block;
        color: #212529;
        font-family: 'karla';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        text-decoration-line: underline;
        text-transform: capitalize;
      }
    }

    &__title {
      margin-bottom: 0.625rem;
      color: #222;
      font-size: 2.25rem;
      font-family: 'Constantia';
      font-weight: 500;
    }

    &__price {
      margin: 1.5rem 0;
      color: #212529;
      font-size: 1.625rem;
      font-weight: 500;
    }
  }

  @media screen and (max-width: 767px) {
    display: block;

    .product {
      &__title {
        font-size: 1.875rem;
      }
    }

    .render__item {
      padding: 0;
    }
  }
`
