import styled from '@emotion/styled'

export const StyledCategoryLayout = styled.div`
  @media screen and (max-width: 767px) {
    .MuiContainer-root {
      padding: 0 0.9375rem;
    }
  }
`

export const StyledLayout = styled.div`
  display: grid;
  grid-template-columns: 11.25rem 1fr;
  grid-column-gap: 3.75rem;

  @media screen and (max-width: 767px) {
    display: block;
  }
`

export const StyledToolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;

    > div {
      flex-basis: 100%;
    }
  }
`

export const StyledWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 1rem;
  align-items: center;

  .category {
    &__name {
      font-size: 1.875rem;
      font-family: silkserif;
    }

    &__count {
      padding-top: 0.55rem;
      color: #222;
      font-size: 0.75rem;
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  @media screen and (max-width: 767px) {
    padding-left: 0.5rem;
  }
`

export const StyledBanner = styled.div`
  cursor: pointer;
`
