import styled from '@emotion/styled'

export const StyledSwiper = styled.div`
  .mySwiper {
  }

  .swiperItem {
    background-color: pink;
    text-align: center;
  }
`
