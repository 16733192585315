import styled from '@emotion/styled'

export const StyledContainer = styled.ul`
  ul {
    padding-left: 10px;
  }
`

export const StyledItem = styled.div`
  cursor: pointer;
`

export const StyledLabel = styled.li`
  width: 100%;
  cursor: pointer;
  padding: 0.5rem 0.9375rem 0.5rem 0.9375rem;
  border: 1px solid #d6d0cb;
  color: #615956;
  font-size: 12px;
  border-radius: unset;
  margin-bottom: 0.75rem;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    border-color: #212529;
  }
  a {
    text-decoration: none;
    color: #615956;
    white-space: nowrap;
  }
  @media screen and (max-width: 767px) {
    padding: 0.9375rem;
    border-color: #d6d0cb;
    font-size: 1rem;
    margin-bottom: 0;
  }
`

export const StyledTabTitle = styled.p`
  padding: 1rem 20px 1rem 0;
  margin-bottom: 0;
  color: #222;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  position: relative;
  &::before {
    position: absolute;
    right: 12px;
    top: 50%;
    margin-top: -8px;
    content: '';
    height: 16px;
    width: 2px;
    background-color: #1b1e22;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  &.active::before {
    transform: rotate(90deg);
  }
  &::after {
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: -1px;
    content: '';
    width: 16px;
    height: 2px;
    background-color: #1b1e22;
  }

  @media screen and (max-width: 767px) {
    padding: 0.9375rem;
    font-size: 1.125rem;
    font-weight: 500;
    text-transform: uppercase;
    border: 1px solid #615956;
    border-bottom: 0;

    &::after {
      top: 1.125rem;
      right: 0.9375rem;
      display: inline-block;
      width: 0.9375rem;
      height: 0.9375rem;
      background: #fff url(/images/gather.svg) no-repeat;
      background-size: 1050%;
      background-position: -23px -316px;
      transition: all 0.3s;
      border-bottom-width: 1px;
    }
    &.active {
      border-bottom: 1px solid #615956;
    }
    &.active::after {
      transform: rotate(-90deg);
    }
    &::before {
      display: none;
    }
  }
`
