import { FC, memo } from 'react'

import ConfigurableItem from './configurableItem'

interface ConfigurableOptionsProps {
  options: any[]
  variants: any[]
  errors: any
  control: any
  setIsPreSalePc: any
}

const ConfigurableOptions: FC<ConfigurableOptionsProps> = ({
  control,
  errors,
  options,
  variants,
  setIsPreSalePc
}) => {
  return (
    <div>
      {options.map((option: any) => {
        return (
          <ConfigurableItem
            key={option.attribute_code}
            control={control}
            errors={errors}
            option={option}
            variants={variants}
            setIsPreSalePc={setIsPreSalePc}
          />
        )
      })}
    </div>
  )
}

export default memo(ConfigurableOptions)
