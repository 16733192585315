import { clsx } from 'clsx'
import { memo } from 'react'

import { Container, I18n, Link } from '@headless/ui'

import { StyledBreadcrumbs } from './styled'

const AppBreadcrumbs = ({ className, children }: any) => {
  return (
    <Container>
      <StyledBreadcrumbs
        className={clsx({
          breadcrumb: true,
          className
        })}
        separator=""
        aria-label="breadcrumb"
      >
        <Link underline="hover" href="/">
          <span>
            <I18n id="global.home" />
          </span>
        </Link>
        {children}
      </StyledBreadcrumbs>
    </Container>
  )
}

export default memo(AppBreadcrumbs)
