import { useQuery } from '@apollo/client'

import { GET_FILTER_INPUTS } from '@graphql/queries/getFilterInputs'

export const useCategoryPage = () => {
  const { data } = useQuery(GET_FILTER_INPUTS)

  const filterInputs: any[] = data?.__type?.inputFields ?? []

  return {
    filterInputs
  }
}
