import { WebsiteConf } from '@config/website'

interface PaginationSearchProps {
  url: string
  pageSize: number
}

export const getPaginationFromSearch = ({
  url,
  pageSize
}: PaginationSearchProps) => {
  const params: any = new URLSearchParams(url)
  const {
    category: { pagination }
  } = WebsiteConf
  const { PAGE_KEY, SIZE_KEY } = pagination
  const pageParam = params.has(PAGE_KEY)
    ? parseInt(params.get(PAGE_KEY), 10)
    : 1

  const sizeParam = params.has(SIZE_KEY)
    ? parseInt(params.get(SIZE_KEY), 10)
    : pageSize

  const sortState = {
    total: 1,
    currentPage: pageParam,
    pageSize: sizeParam
  }

  return sortState
}
