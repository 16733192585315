import styled from '@emotion/styled'

export const StyledOptions = styled.div`
  display: grid;
  margin-bottom: 1rem;

  .swatch__attribute {
    display: grid;
    margin-bottom: 0.5rem;
    color: #000;
    font-size: 1.125rem;
    font-weight: 400;
    grid-auto-flow: column;
    justify-content: flex-start;
  }

  picture {
    display: flex;
    width: 4.6875rem;
    height: 4.6875rem;
    cursor: pointer;
    border: 1px solid #d6d0cb;

    img {
      width: 100%;
      object-fit: cover;
    }

    &.disabled {
      border-style: dashed;
    }

    &:hover {
      border-color: #212529;
    }
  }

  .MuiFormControlLabel-root {
    margin-right: 0.625rem;
    margin-bottom: 0.625rem;
    margin-left: 0;

    .MuiRadio-root {
      width: 0;
      height: 0;
      padding: 0;
      opacity: 0;
      visibility: hidden;
    }
  }

  .Mui-checked + .MuiFormControlLabel-label {
    picture {
      border-color: #212529;
    }

    .option_label {
      border-color: #212529;
      background-color: #fff;
    }
  }

  @media screen and (max-width: 767px) {
    picture {
      width: 3.75rem;
      height: 3.75rem;
    }

    .swatch__attribute {
      font-size: 0.875rem;
    }

    .MuiFormControl-root {
      margin-bottom: 0;
    }
  }
`

export const StyledOption = styled.span`
  display: flex;
  width: 50px;
  height: 30px;
  color: #666;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border: 1px solid #eee;
  background-color: #eee;

  &.disabled {
    color: #cdcbc9;
    opacity: 0.5;
  }

  &:hover {
    border-color: #212529;
    background-color: #fff;
  }
`
