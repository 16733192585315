import { DocumentNode, gql } from '@apollo/client'

export const categoryVariant: DocumentNode = gql`
  fragment categoryVariant on ConfigurableVariant {
    attributes {
      code
      uid
      label
    }
    product {
      sku
      presale_product {
        status
        label
        note
      }
      price_range {
        ...price_range
        __typename
      }
      small_image {
        label
        url(width: 384, height: 512)
      }
      stock_status
      media_gallery {
        disabled
        label
        position
        thumbnail: url(width: 120, height: 160)
        image: url(width: 1125, height: 1500)
        ... on ProductVideo {
          video_content {
            media_type
            video_provider
            video_url
            video_title
            video_description
            video_metadata
          }
        }
        __typename
      }
      thumbnail {
        url(width: 45, height: 60)
      }
      hover_image(width: 384, height: 512)
    }
  }
`
