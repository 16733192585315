import { FC, memo, useMemo } from 'react'

import { useProductPrice } from '@hooks/ProductPrice'

import BasePrice from '@components/BasePrice'
import { useSelector } from 'react-redux'
import { StyledPrice } from './styled'

interface ProductPriceProps {
  minimum_price: any
  maximum_price: any
  qty?: number
  isBundleProduct: any
  product: any
}

const ProductPrice: FC<ProductPriceProps> = ({
  minimum_price = {},
  maximum_price = {},
  isBundleProduct = false,
  qty = 1,
  product,
  ...props
}) => {
  const { hasGroup, hasSpecial } = useProductPrice({
    minimum_price,
    maximum_price
  })

  const selectedOptions = useSelector(
    (state: any) => state.user.selectedOptions
  )

  const bundlePrice = useMemo(() => {
    if (isBundleProduct) {
      const value = product?.prices

      let newFinal_priceValue = value.finalPrice.value

      for (let i = 0; i < selectedOptions?.length; i++) {
        newFinal_priceValue += selectedOptions[i]?.price || 0
      }

      const newValue = {
        ...value,
        final_price: { ...value.finalPrice, value: newFinal_priceValue }
      }

      return newValue
    }
  }, [isBundleProduct, product, selectedOptions])

  if (isBundleProduct) {
    return (
      <StyledPrice {...props}>
        <>
          <BasePrice value={bundlePrice.final_price.value * qty} />
        </>
      </StyledPrice>
    )
  }

  return (
    <StyledPrice {...props}>
      {hasGroup ? (
        <>
          {!hasSpecial && (
            <BasePrice
              className={
                hasSpecial ? 'product__price--old' : 'product__price--default'
              }
              value={
                (minimum_price.regular_price.value ||
                  minimum_price.final_price.value) * qty
              }
            />
          )}
          {hasSpecial && (
            <>
              <BasePrice
                className="product__price--specail"
                value={minimum_price.final_price.value * qty}
              />
              <BasePrice
                crossed
                className="product__price--crossed"
                value={minimum_price.regular_price.value * qty}
              />
            </>
          )}
        </>
      ) : (
        <>
          <BasePrice value={minimum_price.final_price.value * qty} />
        </>
      )}
    </StyledPrice>
  )
}

export default memo(ProductPrice)
