import { actions as catalogActions } from '@store/catalog'
import { clsx } from 'clsx'
import { FC, memo, useState } from 'react'
import LazyLoad from 'react-lazyload'
import { useDispatch } from 'react-redux'
import { StyledOption, StyledOptionItem } from './styled'

interface CustomizeOptionsProps {
  options: any[]
  variants: any[]
  handleSelect: Function
}

const CustomizeOptions: FC<CustomizeOptionsProps> = ({
  options,
  variants,
  handleSelect
}) => {
  const dispatch = useDispatch()

  const [activeCode, setActiveCode] = useState<string>('')

  const handleSwitchImage = (id: string) => {
    const match: any = variants.find((variant: any) => {
      const attribute: any = variant.attributes[0]
      return attribute.uid === id
    })

    return match?.product?.thumbnail?.url ?? ''
  }

  const handleClick: any = async (id: string) => {
    const match = variants.find((variant: any) => {
      const attribute: any = variant.attributes[0]
      return attribute.uid === id
    })

    // 把目录页的这个 match 存到 Redux 里面
    await dispatch(catalogActions.setCatalogSelect(match))

    setActiveCode(id)
    if (match) {
      handleSelect({
        small: match.product.small_image,
        hover: match.product.hover_image
      })
    }
  }

  return (
    <>
      {options.length > 0 && (
        <div className="product__options">
          {options.map((option: any) => {
            const { attribute_code, label, values } = option
            const avliable: any[] = values.slice(0, 3)
            return attribute_code === 'color' ? (
              <div className="option__item" key={attribute_code}>
                {avliable.length > 0 && (
                  <StyledOptionItem>
                    {avliable.map((value: any) => {
                      const { uid } = value
                      const image: string = handleSwitchImage(uid)
                      return (
                        <li
                          aria-hidden="true"
                          className={clsx({
                            active: activeCode === uid
                          })}
                          key={uid}
                          onClick={() => {
                            handleClick(uid)
                          }}
                        >
                          {attribute_code === 'color' ? (
                            <LazyLoad>
                              <picture>
                                <img src={image} alt={value.label} />
                              </picture>
                            </LazyLoad>
                          ) : (
                            <StyledOption
                              dangerouslySetInnerHTML={{
                                __html: value.label
                              }}
                            />
                          )}
                        </li>
                      )
                    })}
                    <li>
                      <span>{`${values.length} ${label}${
                        values.length > 1 ? 's' : ''
                      }`}</span>
                    </li>
                  </StyledOptionItem>
                )}
              </div>
            ) : null
          })}
        </div>
      )}
    </>
  )
}

export default memo(CustomizeOptions)
