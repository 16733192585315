import { Collapse } from '@headless/ui'
import useToggle from '@hooks/useToggle'
import { clsx } from 'clsx'
import CategoryTreeFilterItem from './categoryTreeFilterItem'
import { StyledTabTitle } from './styled'

const CategoryTreeFilterWrapper = ({
  categoryTree
}: {
  categoryTree: Array<any>
}) => {
  const [isExpanded, setIsExpanded] = useToggle(true)

  return (
    <div>
      <StyledTabTitle
        onClick={() => {
          setIsExpanded()
        }}
        className={clsx({
          active: isExpanded
        })}
      >
        <span>Category</span>
      </StyledTabTitle>
      <Collapse in={isExpanded}>
        <ul>
          {categoryTree.map((item: any) => {
            return item.children?.map((temp: any) => {
              return <CategoryTreeFilterItem {...temp} key={temp.value} />
            })
          })}
        </ul>
      </Collapse>
    </div>
  )
}

export default CategoryTreeFilterWrapper
