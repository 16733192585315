import styled from '@emotion/styled'

export const StyledCurrentFilters = styled.ul`
  display: block;
  @media screen and (max-width: 767px) {
    display: flex;
  }
`

export const StyledCurrentFilter = styled.li`
  margin-bottom: 0.5rem;
`
