import { WebsiteConf } from '@config/website'

interface SearchStateProps {
  initialValue: any
  filterKeys: any
  filterState: any
  pagination: any
}
export const DELIMITER = ','

export const stripHtml = (html: string) => html.replace(/(<([^>]+)>)/gi, '')

const getValueFromFilterString = (keyValueString: string) =>
  keyValueString.split(DELIMITER)[1]

export const getSearchFromState = ({
  initialValue,
  filterKeys,
  filterState,
  pagination
}: SearchStateProps) => {
  // preserve all existing params
  const nextParams = new URLSearchParams(initialValue)
  const {
    category: { pagination: paginationConfig }
  } = WebsiteConf
  const { PAGE_KEY, SIZE_KEY } = paginationConfig
  const { currentPage, pageSize } = pagination

  // iterate over available filters
  for (const key of filterKeys) {
    // remove any existing filter values
    nextParams.delete(key)
  }

  // iterate over the latest filter values
  for (const [group, items] of filterState) {
    for (const item of items) {
      const { label, value } = item || {}

      // append the new values
      nextParams.append(`${group}[filter]`, `${label}${DELIMITER}${value}`)
    }
  }

  // rever pagination
  if (nextParams.has(PAGE_KEY)) nextParams.set(PAGE_KEY, currentPage)
  if (nextParams.has(SIZE_KEY)) nextParams.set(SIZE_KEY, pageSize)

  // prepend `?` to the final string
  return nextParams.toString() === '' ? '' : `?${nextParams.toString()}`
}

export const getStateFromPath = (
  aggregations: any,
  categories: any,
  sortFields: any,
  pathname: any,
  filters: any
) => {
  const nextState = new Map()

  const sortFieldOptions = sortFields.options || []

  const pathArr = pathname.split('/')
  const filteredPathArr = pathArr.filter((temp: any) => {
    return (
      temp !== '' &&
      categories.findIndex((item: any) => item.url_key === temp) === -1 &&
      sortFieldOptions.findIndex((item: any) => item.value === temp) === -1 &&
      temp !== 'product_list_order' &&
      temp !== 'p' &&
      !/^\d+$/.test(temp)
    )
  })

  filteredPathArr.forEach((temp: any) => {
    const splited = temp.split('-')
    splited.forEach((splitedItem: any) => {
      const findAggregation =
        aggregations &&
        aggregations.find((aggre: any) => aggre.rewrite === splitedItem)

      if (findAggregation) {
        const matchFilter = filters.find(
          // eslint-disable-next-line @typescript-eslint/no-shadow
          (temp: any) =>
            findAggregation.code === temp.aggregation.attribute_code
        )
        if (matchFilter) {
          const { options } = matchFilter.aggregation
          const findOption = options.find(
            // eslint-disable-next-line @typescript-eslint/no-shadow
            (temp: any) =>
              temp.value.toString() === findAggregation.option_id.toString()
          )

          if (findOption) {
            if (nextState.has(matchFilter.aggregation.attribute_code)) {
              const preItems = nextState.get(
                matchFilter.aggregation.attribute_code
              )
              preItems.add(findOption)
            } else {
              const items = new Set()
              items.add(findOption)
              nextState.set(matchFilter.aggregation.attribute_code, items)
            }
          }
        }
      }
    })
  })
  return nextState
}

/**
 * Looks for filter values within a search string and returns a map like
 * {
 *   "category_id": ["Bottoms,28", "Pants & Shorts,19"]
 * }
 * filter[category_id]=Bottoms,28&filter[category_id]=Pants & Shorts,19
 * @param {String} initialValue a search string, as in from location.search
 */
export const getFiltersFromSearch = (initialValue: string) => {
  // preserve all existing params
  const params = new URLSearchParams(initialValue)
  const uniqueKeys: any = new Set(params.keys())
  const filters = new Map()

  // iterate over existing param keys
  for (const key of uniqueKeys) {
    // if a key matches a known filter, add its items to the next state
    if (key.endsWith('[filter]')) {
      // derive the group by slicing off `[filter]`
      const group = key.slice(0, -8)
      const items = new Set()

      // map item values to items
      for (const value of params.getAll(key)) {
        items.add(value)
      }

      // add items to the next state, keyed by group
      filters.set(group, items)
    }
  }

  return filters
}

/**
 * Converts a set of values to a range filter
 * @param {Set} values
 */
const toRangeFilter = (values: Set<any>) => {
  const rangeString = getValueFromFilterString(Array.from(values)[0])
  const [from, to] = rangeString.split('_')
  const rangeFilter: any = {
    from,
    to
  }

  if (rangeFilter.from === '*') {
    delete rangeFilter.from
  }
  if (rangeFilter.to === '*') {
    delete rangeFilter.to
  }
  return rangeFilter
}

/**
 * Converts a set of values into an equals filter
 * @param {Set} values
 */
const toEqualFilter = (values: Set<any>) => {
  if (values.size > 1) {
    return {
      in: Array.from(values).map(getValueFromFilterString)
    }
  }
  return {
    eq: getValueFromFilterString(Array.from(values)[0])
  }
}

// 颜色聚合
const toColorFilter = (values: Set<any>) => {
  const colorValues = Array.from(values)[0]?.split(',')
  // ["pink", "1234"]
  if (!colorValues || colorValues.length <= 2) {
    return { eq: colorValues[1] }
  }
  // ["purple", "1324, "1234", "9654"]
  const valuesArrayClone = colorValues.slice(1)

  return { in: valuesArrayClone }
}

const toMatchFilter = (values: Set<any>) => {
  return { match: getValueFromFilterString(Array.from(values)[0]) }
}

const CONVERSION_FUNCTIONS: any = {
  FilterEqualTypeInput: toEqualFilter,
  FilterMatchTypeInput: toMatchFilter,
  FilterRangeTypeInput: toRangeFilter,
  FilterColorTypeInput: toColorFilter
}

export const getFilterInput = (values: any, type: string) => {
  const conversionFunction = CONVERSION_FUNCTIONS[type]
  if (!conversionFunction) {
    throw TypeError(`Unknown type ${type}`)
  }

  return conversionFunction(values)
}

export const getRouter = (router: any) => {
  if (!router) return {}
  const asPath = router.asPath || ''
  const [pathname = '', search = ''] = asPath.split('?')

  return { pathname, search }
}
