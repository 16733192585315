import { useEffect, useState } from 'react'
import { X } from 'react-feather'

import { Icon, Drawer } from '@headless/ui'
import { event } from '@headless/utils'

import { StyledCategoryDrawer, StyledClose } from './styled'

const CategoryDrawer = ({ children }: any) => {
  const [open, setOpen] = useState<boolean>(false)

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    const handleOpen = (value: boolean) => {
      setOpen(value)
    }

    event.on('handleOpenFilter', handleOpen)

    return () => {
      event.off('handleOpenFilter', handleOpen)
    }
  }, [])

  return (
    <Drawer
      anchor="right"
      open={open}
      PaperProps={{
        sx: {
          width: '100%'
        }
      }}
      onClose={handleClose}
    >
      <StyledCategoryDrawer>
        <StyledClose>
          <Icon src={X} onClick={handleClose} />
        </StyledClose>
        {children}
      </StyledCategoryDrawer>
    </Drawer>
  )
}

export default CategoryDrawer
