import { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'

import { useAddToCart } from '@headless/hooks'
import { event } from '@headless/utils'

export const useCatalogActions = ({ product, currenct }: any) => {
  const { handleAddToCart } = useAddToCart()
  const [loading, setLoading] = useState<boolean>(false)

  const selectedOptions = useSelector(
    (state: any) => state.user.selectedOptions
  )

  const bundleItemsOptions = useSelector(
    (state: any) => state.user.bundleItemsOptions
  )

  const [isShowMiniTips, setIsShowMiniTips] = useState(false)

  const options: any[] = useMemo(() => {
    return product?.configurable_options ?? []
  }, [product?.configurable_options])
  // 默认选中第一个
  const atrributes: any = options.reduce((prev, next) => {
    return {
      ...prev,
      [next.attribute_code]: options?.[0]?.values?.[0]?.uid || ''
    }
  }, {})

  const {
    control,
    register,
    formState: { errors },
    setValue,
    getValues,
    handleSubmit
  } = useForm({
    defaultValues: {
      quantity: 1,
      ...atrributes
    }
  })

  // 默认选中在目录页里面被选中的
  const catalogSelect = useSelector(
    (state: any) => state?.catalog?.catalogSelect
  )

  useEffect(() => {
    if (!catalogSelect) {
      return
    }

    if (!options) {
      return
    }

    const { attributes } = catalogSelect || {}

    // 判断 options 中是否存在， 不存在直接 return
    const uidToCheck = attributes?.[0]?.uid
    const existsInOptions = options?.[0]?.values?.some(
      (value: any) => value?.uid === uidToCheck
    )

    if (!existsInOptions) {
      return
    }

    setValue(attributes?.[0]?.code, attributes?.[0]?.uid)
  }, [catalogSelect, options, setValue])

  const handleQtyMinus = useCallback(() => {
    const qty = Number(getValues('quantity'))
    setValue('quantity', qty > 1 ? qty - 1 : 1)
  }, [getValues, setValue])

  const handleQtyPlus = useCallback(() => {
    const qty = Number(getValues('quantity'))
    setValue('quantity', qty + 1)
  }, [getValues, setValue])

  const handleQtyChange = useCallback(
    (e: any) => {
      const qty = Number(getValues('quantity'))
      if (!e.target.value) setValue('quantity', qty)
    },
    [getValues, setValue]
  )

  const qty = getValues('quantity')

  const handleOpenNotify = () => {
    event.emit('openNotify', currenct)
  }

  const handleFormSubmit = handleSubmit(async (values: any) => {
    try {
      setLoading(true)

      const { items } = bundleItemsOptions || {}

      const { required } = (items && items[0]) || {}

      await handleAddToCart({
        values,
        product,
        selectedOptions,
        required
      })
      setLoading(false)
      setIsShowMiniTips(true)

      setTimeout(() => {
        setIsShowMiniTips(false)
      }, 3000)
    } catch (error) {
      setLoading(false)
    }
  })

  return {
    control,
    errors,
    loading,
    register,
    handleQtyMinus,
    handleQtyPlus,
    handleQtyChange,
    handleFormSubmit,
    handleOpenNotify,
    isShowMiniTips,
    qty
  }
}
