import styled from '@emotion/styled'

export const StyledCategoryFilter = styled.div`
  position: relative;

  .filters__title {
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 10px;
    color: #222;
    font-size: 1.25rem;
    font-family: 'serif';
    font-weight: 500;
    word-spacing: 0.1em;
    letter-spacing: 1px;
    border-bottom: 1px #e1e1da solid;
    justify-content: space-between;
    align-items: flex-end;

    .MuiButton-root {
      padding: 0;
      color: #52575d;
      font-size: 0.875rem;
      background-color: transparent;
      text-decoration: underline;
      text-transform: capitalize;
      box-shadow: unset;

      &:hover {
        color: #dbb19f;
      }
    }
  }
`

export const StyledFilterContent = styled.div`
  margin-top: 0.5rem;

  @media screen and (max-width: 767px) {
    margin-top: 0;
    .filter__collapse {
      &:not(:last-of-type) {
        .tab__title {
          border-bottom: 0;
        }
      }

      &:last-of-type {
        .MuiCollapse-wrapperInner {
          > div {
            .filter__item {
              &:first-of-type {
                .MuiButton-root {
                  border-top: 0;
                }
              }

              &:last-of-type {
                .MuiButton-root {
                  border-bottom: 1px solid #d6d0cb;
                }
              }
            }
          }
        }
      }
    }
  }
`

export const StyledFilterClearButton = styled.div`
  display: inline-block;
  border: 1px solid #d6d0cb;
  margin-left: 1rem;
  font-size: 12px;
  padding: 0.2rem 0.4rem;
  cursor: pointer;
`

export const StyledSwitchContainer = styled.div`
  .MuiSwitch-root {
    margin-left: -12px;
  }
`
