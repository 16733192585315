import useMediaQuery from '@mui/material/useMediaQuery'
import { FC, memo, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { I18n, Link } from '@headless/ui'
import { event } from '@headless/utils'

import {
  StyledCategoryItem,
  StyledFilterButton,
  StyledFilterItem
} from './styled'

interface FilterItemProps {
  filterState: any
  filterApi: any
  categoryFilter: any
  group: any
  item: any
  count: any
  setIsApplying: any
}

const FilterItem: FC<FilterItemProps> = ({
  filterApi,
  group,
  filterState = new Set(),
  categoryFilter,
  item,
  setIsApplying
}) => {
  const isMobile: boolean = useMediaQuery('(max-width:767px)')
  const storeConfig = useSelector((state: any) => state.app.storeConfig)
  const { toggleItem } = filterApi
  const { count, label, swatch_data } = item
  let isChecked = false
  const values = filterState && filterState.values()
  for (const temp of values) {
    if (temp.value === item.value) {
      isChecked = true
    }
  }

  const isImageSwatch: boolean =
    swatch_data?.type === 'ImageSwatchData' ?? false

  const isCategory: boolean = useMemo(() => {
    return group === 'category_id'
  }, [group])

  const mediaURL: string = useMemo(() => {
    return storeConfig?.secure_base_media_url ?? ''
  }, [storeConfig])

  const isCheckbox = useMemo(() => {
    return ['0', '1'].includes(label)
  }, [label])

  const handleFetchLink = (value: string) => {
    const children: any[] = categoryFilter?.children ?? []
    const match = children.find(
      (category: any) => String(category.id) === value
    )

    return match ? `/${match.url_key}` : `/${categoryFilter?.url_key ?? ''}`
  }

  const handleChange = useCallback(() => {
    if (!isCategory) {
      toggleItem({ group, item })
      setIsApplying(true)
      if (isMobile) event.emit('handleOpenFilter', false)
    }
  }, [toggleItem, group, item, setIsApplying, isCategory, isMobile])

  return (
    <>
      {isCategory ? (
        <StyledCategoryItem className="filter__item">
          <Link href={handleFetchLink(item.value)} title={label}>
            <span dangerouslySetInnerHTML={{ __html: label }} />
            <span>&nbsp;{`(${count})`}</span>
          </Link>
        </StyledCategoryItem>
      ) : (
        <StyledFilterItem className="filter__item">
          <StyledFilterButton
            variant={isChecked ? 'contained' : 'outlined'}
            color={isChecked ? 'success' : 'inherit'}
            onClick={handleChange}
          >
            {swatch_data && (
              <span
                className="swatch"
                style={{
                  backgroundImage: isImageSwatch
                    ? `url(${mediaURL}attribute/swatch/swatch_image/30x20${swatch_data.value})`
                    : 'none',
                  backgroundColor: isImageSwatch
                    ? 'transparent'
                    : swatch_data.value,
                  border:
                    swatch_data.value === '#ffffff' ? '1px solid #ccc' : 'none'
                }}
              />
            )}
            {isCheckbox ? (
              <I18n id={label === '0' ? 'global.yes' : 'global.no'} />
            ) : (
              <span dangerouslySetInnerHTML={{ __html: label }} />
            )}
            {!swatch_data && <span>&nbsp;{`(${count})`}</span>}
          </StyledFilterButton>
        </StyledFilterItem>
      )}
    </>
  )
}

export default memo(FilterItem)
