import { useMutation } from '@apollo/client'
import { find } from 'lodash'
import { useSnackbar } from 'notistack'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

import { CREATE_REVIEW_MUTATION } from '@graphql/mutations/createProductReview'
import { GET_PRODUCT_REVIEW } from '@graphql/queries/getProductReview'
import { useAwaitQuery } from '@headless/hooks'
import { event } from '@headless/utils'

interface useCatalogReviewsProps {
  sku: string
  defaultColor: any
}

export const useCatalogReviews = (props: useCatalogReviewsProps) => {
  const { sku, defaultColor } = props

  const { enqueueSnackbar } = useSnackbar()
  const [createReviewMutation] = useMutation(CREATE_REVIEW_MUTATION)
  const getProductReview: any = useAwaitQuery(GET_PRODUCT_REVIEW)
  const {
    control,
    formState: { errors, isDirty },
    reset,
    handleSubmit,
    setValue
  } = useForm({
    defaultValues: {
      nickname: '',
      summary: '',
      text: '',
      color: defaultColor
    }
  })
  const [result, setResult] = useState<any>(null)
  const [collapsed, setCollapsed] = useState<boolean>(false)
  const [sort, setSort] = useState<string>('most_helpful')
  const [apiSort, setApiSort] = useState<any>({
    most_recent: 'DESC',
    with_pic: true
  })
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [ratings, setRatings] = useState<any>(null)
  const [images, setImages] = useState<any[]>([])

  const reviews = useMemo(() => {
    return result?.products?.items[0]?.reviews?.items ?? []
  }, [result])

  const reviewMeta = useMemo(() => {
    return result?.reviewMeta?.items ?? []
  }, [result])

  const thumbnailImages = useMemo(() => {
    const thumbnail: string[] = []

    reviews.forEach((review: any) => {
      const { review_image } = review
      review_image.forEach((item: any) => {
        thumbnail.push(item.url)
      })
    })
    return thumbnail
  }, [reviews])

  const totalPage = useMemo(() => {
    return result?.products?.items[0]?.reviews?.page_info?.total_pages ?? 1
  }, [result])

  const formatDate = (value: string) => {
    const date = new Date(value.replace(/-/g, '/'))
    const y = `${date.getFullYear()}`.padStart(4, '0')
    const m = `${date.getMonth() + 1}`.padStart(2, '0')
    const d = `${date.getDate()}`.padStart(2, '0')
    const time = [m, d, y].join('/')

    return time
  }

  const handleCollapseClick = () => {
    setCollapsed((prevState: any) => !prevState)
  }

  const handleSortChange = (e: any) => {
    setSort(e.target.value)
    setApiSort((prevState: any) => {
      return {
        with_pic: prevState.with_pic,
        [e.target.value]: 'DESC'
      }
    })
  }

  const handleSortChecked = (e: any) => {
    setApiSort((prevState: any) => {
      return {
        ...prevState,
        with_pic: e.target.checked
      }
    })
  }

  const handleImageChange = (imageList: any) => {
    setImages(imageList)
  }

  const handleImageClick = (url: string) => {
    event.emit('handleOpenLight', url)
  }

  const handleFetchReview = async () => {
    try {
      const { data } = await getProductReview({
        variables: {
          filter: {
            sku: { eq: sku }
          },
          pageSize: 10,
          currentPage,
          sort: apiSort
        }
      })
      setResult(data || {})
    } catch (error) {
      return null
    }
  }

  const handleFormSubmit = handleSubmit(async (values: any) => {
    try {
      if (ratings.length === 0) return false
      const params: any[] = []
      Object.entries(ratings).forEach((rating: any) => {
        const name: any = rating[0]
        const value: any = rating[1]
        const activeData: any = find(
          reviewMeta,
          (meta) => `rating_${meta.id}` === name
        )

        params.push({
          id: activeData.id,
          value_id: activeData.values[value - 1].value_id
        })
      })
      const imgURL: string[] = images.reduce((prev: any, next: any) => {
        return [...prev, next.data_url]
      }, [])
      const { data } = await createReviewMutation({
        variables: {
          ...values,
          sku,
          ratings: params,
          imgurl: imgURL
        }
      })
      const response: any = data?.createProductReview?.review ?? null
      if (response) {
        setCollapsed(false)
        setImages([])
        reset({
          nickname: '',
          summary: '',
          text: '',
          color: ''
        })
        await enqueueSnackbar(`You had submited review success!`, {
          variant: 'success'
        })
        await handleFetchReview()
      }
    } catch (error) {
      return null
    }
  })

  useEffect(() => {
    const fetchProductReview = async () => {
      try {
        const { data } = await getProductReview({
          variables: {
            filter: {
              sku: { eq: sku }
            },
            pageSize: 10,
            currentPage,
            sort: apiSort
          }
        })
        setResult(data || {})
      } catch (error) {
        return null
      }
    }

    fetchProductReview()
  }, [getProductReview, sku, currentPage, apiSort])

  useEffect(() => {
    const defaultRating = reviewMeta.reduce((meta: any, next: any) => {
      return {
        ...meta,
        [`rating_${next.id}`]: 5
      }
    }, {})
    setRatings(defaultRating)
  }, [reviewMeta])

  return {
    collapsed,
    control,
    errors,
    images,
    isDirty,
    reviews,
    reviewMeta,
    sort,
    currentPage,
    ratings,
    totalPage,
    thumbnailImages,
    formatDate,
    setCurrentPage,
    setRatings,
    handleCollapseClick,
    handleSortChecked,
    handleFormSubmit,
    handleSortChange,
    handleImageChange,
    handleImageClick,
    setValue
  }
}
