import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { WebsiteConf } from '@config/website'
import { getParamsFormQuery, getRouter } from '@headless/utils'

interface PaginationProps {
  pageSize: number
  currentPage?: number
}

const {
  category: { pagination }
} = WebsiteConf

const { PAGE_KEY, SIZE_KEY } = pagination

export const usePagination = ({ pageSize, currentPage }: PaginationProps) => {
  const router = useRouter()
  const [paginationState, setPaginationState] = useState({
    currentPage: currentPage || 1,
    pageSize,
    total: 1
  })

  const setCurrentPage = useCallback(
    (page: number) => {
      const { asPath, query } = router
      const search: any = getParamsFormQuery(query, ['pathname'])
      const queryParams: any = new URLSearchParams(search)
      const queryPath: any = asPath.split('?')[0]

      const { pathname } = getRouter(router)

      const isSearchPage = pathname.indexOf('/search') === 0

      if (isSearchPage) {
        queryParams.set(PAGE_KEY, page)

        setPaginationState((prevState) => ({ ...prevState, currentPage: page }))
        router.push(`${queryPath}?${queryParams.toString()}`)
      } else {
        setPaginationState((prevState) => ({ ...prevState, currentPage: page }))
        const subPaths = asPath.split('/')

        const index = subPaths.findIndex((temp) => temp === 'p')

        if (index > -1) {
          if (page === 1) {
            subPaths.splice(index, 2)
          } else {
            subPaths[index + 1] = page.toString()
          }
        } else {
          subPaths.splice(2, 0, 'p', page.toString())
        }
        router.push(subPaths.join('/'))
      }
    },
    [router]
  )

  const setPageSize = useCallback(
    (size: any) => {
      const { asPath, query } = router
      const search = getParamsFormQuery(query, ['pathname'])
      const queryParams = new URLSearchParams(search)
      const queryPath = asPath.split('?')[0]

      queryParams.set(PAGE_KEY, size)

      setPaginationState((prevState) => ({ ...prevState, pageSize: size }))

      router.push(`${queryPath}?${queryParams.toString()}`)
    },
    [router]
  )

  const setTotal = useCallback((count: any) => {
    setPaginationState((prevState) => ({ ...prevState, total: count }))
  }, [])

  useEffect(() => {
    const { query } = router
    const prams: any = query
    const pageParam = parseInt(prams[PAGE_KEY], 10)
    const sizeParam = parseInt(prams[SIZE_KEY], 10)

    if (pageParam || sizeParam) {
      setPaginationState((prevState) => ({
        ...prevState,
        currentPage: pageParam || 1,
        pageSize: sizeParam || pageSize
      }))
    }
  }, [pageSize, router])

  const api = useMemo(
    () => ({
      setCurrentPage,
      setPageSize,
      setTotal
    }),
    [setCurrentPage, setPageSize, setTotal]
  )

  return [paginationState, api]
}
