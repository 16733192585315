import { DocumentNode, gql } from '@apollo/client'
import { categoryProduct } from '../fragment/categoryProduct'
import { price_range } from '../fragment/priceRange'
import { price_tiers } from '../fragment/priceTiers'

export const GET_PRODUCT_LIST: DocumentNode = gql`
  query getProductList(
    $search: String
    $filters: ProductAttributeFilterInput
    $pageSize: Int
    $currentPage: Int
    $sort: ProductAttributeSortInput
  ) {
    products(
      search: $search
      pageSize: $pageSize
      currentPage: $currentPage
      filter: $filters
      sort: $sort
    ) {
      items {
        id
        name
        sku
        uid
        url_key
        main_image: image {
          label
          url
        }
        video_url
        small_image {
          label
          url(width: 384, height: 512)
        }
        price_range {
          ...price_range
          __typename
        }
        price_tiers {
          ...price_tiers
          __typename
        }
        hover_image(width: 384, height: 512)
        stock_status
        presale_product {
          status
          label
          note
        }
        product_mark
        review_count
        rating_summary
        media_gallery {
          disabled
          label
          position
          thumbnail: url(type: "thumbnail", width: 120, height: 160)
          image: url(type: "image", width: 1125, height: 1500)
          # thumbnail: url(width: 1125, height: 1500)
          ... on ProductVideo {
            video_content {
              media_type
              video_provider
              video_url
              video_title
              video_description
              video_metadata
            }
          }
          __typename
        }
        ...categoryProduct
        __typename
      }
      total_count
    }
  }
  ${price_range}
  ${categoryProduct}
  ${price_tiers}
`
