import styled from '@emotion/styled'

export const StyledPrice = styled.p`
  margin-block-end: 0.2rem;
  color: #222;
  font-size: 0.875rem;

  .product__price {
    &--old {
      margin-right: 0.25rem;
      text-decoration: line-through;
    }

    &--specail {
      color: #52575d;
    }
  }
`
