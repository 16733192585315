import styled from '@emotion/styled'

import { Rating } from '@headless/ui'

export const StyledProductRating = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 0.5rem;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;

  .count {
    font-size: 0.875rem;
  }

  @media screen and (max-width: 767px) {
    .count {
      color: #666;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }
  }
`

export const StyledRating = styled(Rating)({
  '&': {
    fontSize: '1.2rem'
  },
  '& .MuiRating-iconFilled': {
    color: '#000'
  },
  '& .MuiRating-iconEmpty': {
    color: '#ccc'
  }
})
