import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { WebsiteConf } from '@config/website'
import { getParamsFormQuery } from '@headless/utils'

const {
  category: { sort }
} = WebsiteConf
const { SORT_KEY, sortCode, sortDirection } = sort

export const useSort = (params: any) => {
  const router = useRouter()
  const [sortState, setSortState] = useState({
    sortCode: params || sortCode,
    sortDirection
  })

  const setSort = useCallback(
    (key: string) => {
      const { asPath, query } = router
      const { sortDirection: direction } = sortState
      const search: string = getParamsFormQuery(query, ['pathname'])
      const queryParams: any = new URLSearchParams(search)
      const queryPath: any = asPath.split('?')[0]
      let activeDirection: string = direction

      if (key === 'position') activeDirection = 'ASC'
      if (key === 'high_to_low') activeDirection = 'DESC'
      if (key === 'low_to_high') activeDirection = 'ASC'
      if (key === 'new_arrivals') activeDirection = 'DESC'

      setSortState((prevState) => ({ ...prevState, sortCode: key }))

      const subPaths = (asPath.split('?').shift() || '').split('/')

      const index = subPaths.findIndex((temp) => temp === 'product_list_order')
      if (index > -1) {
        subPaths[index + 1] = key
      } else {
        subPaths.splice(2, 0, 'product_list_order', key)
      }

      router.push(`${subPaths.join('/')}`)
    },
    [router, sortState]
  )

  const setOrder = useCallback(() => {
    const { asPath, query } = router
    const { sortCode: code, sortDirection: direction } = sortState
    const search: any = getParamsFormQuery(query, ['pathname'])
    const queryParams: any = new URLSearchParams(search)
    const queryPath: any = asPath.split('?')[0]

    if (queryParams.has(SORT_KEY)) {
      queryParams.set(
        SORT_KEY,
        `${code}_${direction === 'DESC' ? 'asc' : 'desc'}`
      )
    } else {
      queryParams.append(
        SORT_KEY,
        `${code}_${direction === 'DESC' ? 'asc' : 'desc'}`
      )
    }

    setSortState((prevState) => {
      const nextDirection = prevState.sortDirection === 'DESC' ? 'ASC' : 'DESC'
      return { ...prevState, sortDirection: nextDirection }
    })

    router.push(`${queryPath}?${queryParams.toString()}`)
  }, [router, sortState])

  useEffect(() => {
    const sortParam: any = router.query[SORT_KEY]

    const { asPath } = router

    const paths = asPath.split('?').shift()?.split('/')

    const index = paths?.findIndex((temp) => temp === 'product_list_order')

    if (index !== undefined && index > -1) {
      // const result: any = sortParam.split('_')
      // const code: string = result.slice(0, result.length - 1).join('_')
      // const direction: string = result.pop()
      const code = paths![index + 1]

      setSortState((prevState) => {
        return { ...prevState, sortCode: code }
      })
    }
  }, [router])

  const api = useMemo(
    () => ({
      setOrder,
      setSort
    }),
    [setOrder, setSort]
  )

  return [sortState, api]
}
