import { StyleErrorMessage, StyleSuccessMessage } from './styled'

interface SuccessOrErrorMessageProps {
  message: string
  successOrError: 0 | 1 // 0 是 Error
  isShow: boolean
}

function SuccessOrErrorMessage(props: SuccessOrErrorMessageProps) {
  const { message, successOrError, isShow } = props

  if (!isShow) {
    return null
  }

  if (successOrError) {
    return <StyleSuccessMessage>{message}</StyleSuccessMessage>
  }
  return <StyleErrorMessage>{message}</StyleErrorMessage>
}

export default SuccessOrErrorMessage
