import { Link } from '@headless/ui'
import { useRouter } from 'next/router'
import { FC, memo } from 'react'
import { StyledItem, StyledLabel } from './styled'

interface CategoryTreeFilterItemProps {
  label: string
  url_key: string
  children?: Array<CategoryTreeFilterItemProps>
}

const CategoryTreeFilterItem: FC<CategoryTreeFilterItemProps> = ({
  label,
  url_key,
  children
}: CategoryTreeFilterItemProps) => {
  const router = useRouter()

  const onClick = () => {
    router.push(url_key)
  }

  return (
    <StyledItem>
      <StyledLabel onClick={onClick}>
        <Link href={url_key} title={label}>
          {label}
        </Link>
      </StyledLabel>
      <ul>
        {children?.map((child) => (
          <CategoryTreeFilterItem {...child} />
        ))}
      </ul>
    </StyledItem>
  )
}

export default memo(CategoryTreeFilterItem)
