import { useEffect, useState } from 'react'

import { GET_PRODUCT_UPSELL } from '@graphql/queries/getUpsellProduct'
import { useAwaitQuery } from '@headless/hooks'

export const useCatalogRelated = (url: string) => {
  const getRelatedProducts: any = useAwaitQuery(GET_PRODUCT_UPSELL)
  const [products, setProducts] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    const fetchRelatedProducts = async () => {
      try {
        setLoading(true)
        const { data } = await getRelatedProducts({
          variables: {
            filter: {
              url_key: {
                eq: url
              }
            }
          },
          fetchPolicy: 'no-cache'
        })

        const result: any[] = data?.products?.items?.[0]?.upsell ?? []
        setProducts([...result])
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }

    fetchRelatedProducts()
  }, [getRelatedProducts, url])

  return {
    loading,
    products
  }
}
