import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { clsx } from 'clsx'
import { FC, useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'

import { formatMessage } from '@headless/ui'
import { event } from '@headless/utils'

import { StyledOption, StyledOptions } from './styled'

interface ConfigurableItemProps {
  option: any
  variants: any[]
  errors: any
  control: any
  setIsPreSalePc: any
}

const ConfigurableItem: FC<ConfigurableItemProps> = ({
  control,
  errors,
  option,
  variants,
  setIsPreSalePc
}) => {
  const [activeCode, setActiveCode] = useState<any>(null)
  const attrs: any[] = option.values.reduce((prev: any, next: any) => {
    return [
      ...prev,
      {
        label: next.label,
        value: next.uid
      }
    ]
  }, [])
  const name: string = option.attribute_code
  const fieldsProps: any = {
    type: 'radio',
    name,
    key: name,
    rules: {
      required: formatMessage({ id: 'global.required' })
    },
    control
  }

  const handleSwitchImage = (id: string) => {
    const match = variants.find((variant: any) => {
      const attribute: any = variant.attributes.find(
        (item: any) => item.code === name
      )
      return attribute.uid === id
    })

    const { product } = match || {}

    if (match && match.product) {
      const { media_gallery } = product || {}

      const media_gallery_clone = [...media_gallery]

      media_gallery_clone.sort((a: any, b: any) => a.position - b.position)
      const urlGallery = media_gallery_clone[0].image

      return {
        url: urlGallery ?? '',
        stock: match?.product?.stock_status ?? ''
      }
    }

    return {
      url: match?.product?.small_image?.url ?? '',
      stock: match?.product?.stock_status ?? ''
    }
  }

  const handleChange = (code: any, params: any) => {
    const { label, value } = params

    const match: any = variants.find((variant: any) => {
      const attribute: any = variant.attributes.find(
        (item: any) => item.code === name
      )
      return attribute.uid === value
    })

    setActiveCode(label)

    const { product } = match || {}

    const { presale_product } = product || {}

    const { status: isPreOrder } = presale_product

    setIsPreSalePc(isPreOrder)

    if (match) {
      event.emit('updateProductItem', {
        image: match.product.small_image,
        gallery: match.product.media_gallery,
        price: match.product.price_range,
        stock: match.product.stock_status,
        sku: match.product.sku,
        option: {
          code: code.attribute_code,
          name: code.label,
          label,
          value
        }
      })
    }
  }

  useEffect(() => {
    handleChange(option, attrs[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StyledOptions>
      <p className="swatch__attribute">
        <span dangerouslySetInnerHTML={{ __html: option.label }} />
        {activeCode && (
          <span dangerouslySetInnerHTML={{ __html: `: ${activeCode}` }} />
        )}
      </p>
      <FormControl>
        <Controller
          {...fieldsProps}
          render={({ field }) => (
            <RadioGroup {...field} row>
              {attrs.map((attr: any) => {
                const match: any = handleSwitchImage(attr.value)
                return (
                  <div key={attr.value}>
                    <FormControlLabel
                      value={attr.value}
                      label={
                        <span className="method__item">
                          {option.attribute_code === 'color' ? (
                            <picture
                              className={clsx({
                                disabled: match.stock === 'OUT_OF_STOCK'
                              })}
                            >
                              <img src={match.url} alt={attr.label} />
                            </picture>
                          ) : (
                            <StyledOption
                              className={clsx({
                                option_label: true,
                                disabled: match.stock === 'OUT_OF_STOCK'
                              })}
                              dangerouslySetInnerHTML={{
                                __html: attr.label
                              }}
                            />
                          )}
                        </span>
                      }
                      control={
                        <Radio
                          size="small"
                          onChange={() => {
                            handleChange(option, attr)
                          }}
                        />
                      }
                    />
                  </div>
                )
              })}
            </RadioGroup>
          )}
        />
        {errors[name] && (
          <FormHelperText error={!!errors[name]}>
            {(errors as any)[name] ? (errors as any)[name].message : null}
          </FormHelperText>
        )}
      </FormControl>
    </StyledOptions>
  )
}

export default ConfigurableItem
