import { GET_CATEGORY_DETAIL } from '@graphql/queries/getCategoryDetail'
import { has } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { GET_PRODUCT_LIST } from '@graphql/queries/getProductList'
import { useAwaitQuery, usePagination } from '@headless/hooks'
import {
  getFilterInput,
  getModeFromSearch,
  getPaginationFromSearch
} from '@headless/utils'

interface CategoryListProps {
  id?: number
  filterInputs: any[]
  q?: string
  search?: string
  page?: number
  filters?: any[]
  aggregations?: any[]
  sortBy?: any
}

function getFiltersFromUrl(url: string, filters?: any[]): Map<string, any> {
  const map = new Map()
  if (filters && filters.length > 0) {
    filters.forEach((filter: any) => {
      const { option, aggregation } = filter

      if (aggregation?.attribute_code === 'quantity_and_stock_status_bucket') {
        map.set(
          'quantity_and_stock_status',
          new Set([`${option.label},${option.value}`])
        )
        return
      }

      if (option.values && option.values.length > 0) {
        map.set(
          aggregation.attribute_code,
          new Set([`${option.label},${option.values}`])
        )
      } else {
        map.set(
          aggregation.attribute_code,
          new Set([`${option.label},${option.value}`])
        )
      }
    })
  }

  return map
}

export const useCategoryList = ({
  id,
  filterInputs,
  q,
  search,
  page,
  filters,
  sortBy
}: CategoryListProps) => {
  const storeConfig = useSelector((state: any) => state.app.storeConfig)
  const { grid_page, grid_values, list_mode, list_page, list_values } =
    storeConfig
  const url: any =
    typeof window === 'undefined' ? search : window.location.search
  const modeState = getModeFromSearch(url)
  const newMode = modeState.get('mode') || list_mode.split('-')[0]
  const isGrid = newMode === 'grid'
  const pageSize = isGrid ? grid_page : list_page
  const pageOptions = isGrid ? grid_values : list_values

  const [loading, setLoading] = useState(false)
  const [categoryData, setCategoryData] = useState<any>(null)
  const [categoryList, setCategoryList] = useState<any>(null)

  const filterTypeMap = useMemo(() => {
    const typeMap = new Map()
    if (filterInputs.length) {
      filterInputs.forEach(({ name, type }) => {
        typeMap.set(name, type.name)
      })
    }
    return typeMap
  }, [filterInputs])

  const getProductListQuery: any = useAwaitQuery(GET_PRODUCT_LIST)
  const getCatalogQuery: any = useAwaitQuery(GET_CATEGORY_DETAIL)
  const pageControl = usePagination({ pageSize, currentPage: page })

  const paginationState = getPaginationFromSearch({ url, pageSize })
  if (page) {
    paginationState.currentPage = page
  }
  const defaultPageSize = paginationState.pageSize || pageSize
  const pageSizeOptions = pageOptions
    .split(',')
    .map((item: any) => Number(item))

  useEffect(() => {
    const queryProductList = async () => {
      // sort
      const newSort: any = {}

      const sortMap = new Map()

      if (sortBy && sortBy.value) {
        if (sortBy.value === 'low_to_high') {
          sortMap.set(sortBy.value, 'asc')
        } else {
          sortMap.set(sortBy.value, 'desc')
        }
      } else if (q) {
        sortMap.set('relevance', 'desc')
      } else {
        sortMap.set('position', 'desc')
      }

      sortMap.forEach((value, key) => {
        newSort[key] = value.toUpperCase()
      })

      // filters

      const filtersMap = getFiltersFromUrl(url, filters)
      const newFilters: any = {}
      filtersMap.forEach((values, key) => {
        if (key === 'color') {
          newFilters[key] = getFilterInput(values, 'FilterColorTypeInput')
        } else if (filterTypeMap.get(key)) {
          newFilters[key] = getFilterInput(values, filterTypeMap.get(key))
        }
      })

      if (!has(newFilters, 'category_id') && !q)
        newFilters.category_id = { eq: id }

      try {
        const params = {
          search: q,
          pageSize: defaultPageSize,
          currentPage: paginationState.currentPage,
          filters: newFilters,
          sort: newSort
        }

        setLoading(true)

        const { data } = await getProductListQuery({
          variables: params
        })

        setCategoryData(data)

        const {
          category_id: { eq }
        } = newFilters

        const { data: categoryListData } = await getCatalogQuery({
          variables: {
            filters: {
              ids: {
                eq
              }
            },
            currentPage: paginationState.currentPage
          }
        })

        setCategoryList(categoryListData)

        setLoading(false)
      } catch {
        setLoading(false)
        // eslint-disable-next-line no-empty
      }
    }
    queryProductList()
  }, [
    q,
    defaultPageSize,
    paginationState,
    filterInputs,
    sortBy,
    url,
    filters,
    id,
    filterTypeMap,
    getProductListQuery,
    getCatalogQuery
  ])

  useEffect(() => {
    if (!categoryData || !categoryList) return
    const { products } = categoryData
    const { categoryList: categories } = categoryList

    const productItems = products.items.map((temp: any) => {
      return {
        item_name: temp.name,
        item_id: temp.uid,
        quantity: 1
      }
    })

    const productItemsForPintrk = products.items.map((temp: any) => {
      return {
        product_name: temp.name,
        product_id: temp.uid,
        product_category: categories[0].meta_title,
        product_price: temp.price_range.maximum_price.final_price.value,
        product_brand: 'sweetv'
      }
    })

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    window.dataLayer && window.dataLayer.push({ ecommerce: null })
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    window.dataLayer &&
      window.dataLayer.push({
        event: 'view_item_list',
        ecommerce: {
          item_list_id: categories[0].uid,
          item_list_name: categories[0].meta_title,
          items: productItems
        }
      })

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    window.pintrk &&
      window.pintrk('track', 'viewcategory', {
        line_items: productItemsForPintrk
      })
  }, [categoryData, categoryList])

  return {
    categoryData,
    defaultPageSize,
    loading,
    pageControl,
    pageSizeOptions
  }
}
