import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { actions as userActions } from '@store/user'
import { useDispatch, useSelector } from 'react-redux'
import { StyledRoot } from './styled'

const BundleOptions = () => {
  const selectedOptions = useSelector(
    (state: any) => state.user.selectedOptions
  )

  const bundleItemsOptions = useSelector(
    (state: any) => state.user.bundleItemsOptions
  )

  const dispatch = useDispatch()

  const { options } = bundleItemsOptions || {}

  const handleOptionToggle = async (option: any) => {
    if (!selectedOptions) return
    if (selectedOptions?.includes(option)) {
      await dispatch(
        userActions.setSelectedOptions(
          selectedOptions.filter((item: any) => item !== option)
        )
      )
    } else {
      await dispatch(
        userActions.setSelectedOptions([...selectedOptions, option])
      )
    }
  }

  if (!options) {
    return null
  }

  if (options?.length === 0) {
    return null
  }

  return (
    <StyledRoot>
      {options.map((bundleItemsOption: any, index: any) => {
        return (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={selectedOptions?.includes(bundleItemsOption) || false}
                onChange={() => handleOptionToggle(bundleItemsOption)}
                data-cy={bundleItemsOption.label}
              />
            }
            label={bundleItemsOption.label}
          />
        )
      })}
    </StyledRoot>
  )
}

export default BundleOptions
