import { FC, memo } from 'react'

import { I18n, Pagination, Portal } from '@headless/ui'
import { useCategoryList } from '@hooks/CategoryLayout'

import ProductItem from '@components/ProductItem'
import ProductItemShimmer from '@components/ProductItem/productItemShimmer'
import { StyledPagination, StyledProductList } from './styled'

interface CategoryListProps {
  id?: number
  filterInputs: any[]
  q?: string
  search?: string
  page?: number
  filters?: any[]
  aggregations?: any[]
  sortBy?: any
}

const CategoryList: FC<CategoryListProps> = ({
  id,
  filterInputs,
  q,
  search,
  page,
  filters = [],
  aggregations = [],
  sortBy = {}
}) => {
  const { categoryData, loading, pageControl } = useCategoryList({
    id,
    filterInputs,
    q,
    search,
    page,
    filters,
    aggregations,
    sortBy
  })
  const [paginationState, paginationApi]: any = pageControl

  const { currentPage, pageSize } = paginationState
  const { setCurrentPage } = paginationApi

  const productList = categoryData?.products?.items ?? []
  const totalCount = categoryData?.products?.total_count ?? 0

  if (loading) {
    return (
      <StyledProductList>
        {Array.from({ length: 16 }).map((item, index) => (
          <ProductItemShimmer key={index} />
        ))}
      </StyledProductList>
    )
  }

  return (
    <div>
      {productList.length > 0 ? (
        <>
          <StyledProductList>
            {productList.map((product: any) => {
              return (
                <ProductItem
                  key={product.id}
                  product={product}
                  filters={filters}
                />
              )
            })}
          </StyledProductList>
          <StyledPagination>
            <Pagination
              count={Math.ceil(totalCount / pageSize)}
              page={currentPage}
              onChange={(e: any, p: number) => setCurrentPage(p)}
            />
          </StyledPagination>
        </>
      ) : (
        <I18n id="catalog.noResult" />
      )}
      {typeof window !== 'undefined' && totalCount > 0 && (
        <Portal container={document.querySelector('.category__count')}>
          <span className="count">{`${totalCount} ${
            totalCount > 1 ? 'Items' : 'Item'
          }`}</span>
        </Portal>
      )}
    </div>
  )
}

export default memo(CategoryList)
