import { gql, DocumentNode } from '@apollo/client'

export const ADD_PRODUCT_TIP: DocumentNode = gql`
  mutation addProductTip($input: ProductTipInput) {
    addProductTip(input: $input) {
      code
      content
      message
    }
  }
`
