import styled from '@emotion/styled'

import { TextField } from '@headless/ui'

export const StyledCatalogActions = styled.div`
  margin-top: 1rem;
`

export const StyledTextField = styled(TextField)({
  '&': {
    width: '10rem'
  },
  '& .MuiInputBase-root': {
    padding: '0 0.75rem',
    borderRadius: 'unset'
  },
  '& .MuiInputBase-input': {
    padding: '0.725rem 0.5rem',
    color: '#222',
    fontSize: '1rem',
    textAlign: 'center',
    appearance: 'none'
  },
  '& .MuiInputAdornment-root': {
    marginTop: '0.3rem'
  }
})

export const StyleText = styled.div`
  text-align: center;
  display: block;
  width: 100%;
  line-height: 40px;
  background: #f8f8f8;
  margin-bottom: 10px;
  font-family: 'karla';
  color: #d22c2c;
`

export const StyledButton = styled.div`
  margin-top: 1.25rem;

  .MuiButtonBase-root {
    font-size: 1.125rem;
    font-weight: 500;
    background-color: #212529;
    border-radius: unset;
    border: 1px solid #212529;
    letter-spacing: 0.03125rem;

    &.Mui-disabled {
      background-color: #212529;
    }

    &:hover {
      color: #212529;
      background-color: #fff;
    }
  }

  .MuiLoadingButton-loadingIndicator {
    color: #fff;
  }
`

export const StyledPreOrderButton = styled.div`
  margin-top: 1.25rem;

  .MuiButtonBase-root {
    font-size: 1.125rem;
    font-weight: 500;
    background-color: #212529;
    border-radius: unset;
    border: 1px solid #212529;
    letter-spacing: 0.03125rem;

    &.Mui-disabled {
      background-color: #212529;
    }

    &:hover {
      color: #212529;
      background-color: #fff;
    }

    &::before {
      content: '';
      width: 23px;
      height: 23px;
      color: #fff;
      background: url('/images/preorder.svg') no-repeat;
      margin-right: 10px;
    }

    &:hover {
      &::before {
        background: url('/images/preorder2.svg') no-repeat;
      }
    }
  }

  .MuiLoadingButton-loadingIndicator {
    color: #fff;
  }
`

export const StyledStock = styled.div`
  p {
    margin-bottom: 0.625rem;
    color: #d22c2c;
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
  }

  .MuiButton-root {
    padding: 0.5rem 1rem;
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: 0.075em;
    border-radius: 0;
    background-color: #212529;
    border: 1px solid #212529;

    &:hover {
      color: #212529;
      background-color: transparent;
    }
  }
`

export const StyledAddToCartSuccess = styled.div`
  position: fixed;
  width: 100vw;
  background: #fff;
  top: 80px;
  left: 0;
  box-shadow: 3px 0px 2px 2px rgb(0 0 0 / 10%);
  z-index: 31;
  padding: 20px 10px 10px 10px;
  transform: translateY(-200px);
  transition: all 150ms linear;

  &.open {
    transform: translateY(0);
  }

  p {
    text-align: center;
  }
`
