import { Link } from '@headless/ui'
import { FC, memo } from 'react'
import { StyledContainer, StyledText } from './styled'

interface FeaturedFilterProps {
  category: {
    id: number
    name: string
    uid: string
    url_key: string
  }
}

const FeaturedFilter: FC<FeaturedFilterProps> = ({
  category
}: FeaturedFilterProps) => {
  return (
    <StyledContainer>
      <StyledText>
        <Link href={`/${category.url_key}`}>{category.name}</Link>
      </StyledText>
    </StyledContainer>
  )
}

export default memo(FeaturedFilter)
