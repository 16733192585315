import { FC, memo, useMemo } from 'react'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'

interface ImageLightboxProps {
  images: any[]
  index: number
  isOpen?: boolean
  onCloseRequest: Function
  onMovePrevRequest: Function
  onMoveNextRequest: Function
}

const ImageLightbox: FC<ImageLightboxProps> = ({
  images,
  index,
  isOpen,
  onCloseRequest,
  onMovePrevRequest,
  onMoveNextRequest
}) => {
  const thumbnail: string[] = useMemo(() => {
    const reuslt: any[] = []

    images.forEach((item: string) => {
      reuslt.push(item.replace('thumb_', ''))
    })

    return reuslt
  }, [images])

  return (
    <div>
      {isOpen && (
        <Lightbox
          mainSrc={thumbnail[index]}
          nextSrc={thumbnail[index + 1]}
          prevSrc={thumbnail[index - 1]}
          imageCaption={`${index + 1} of ${thumbnail.length}`}
          enableZoom
          onCloseRequest={() => onCloseRequest()}
          onMovePrevRequest={() => onMovePrevRequest()}
          onMoveNextRequest={() => onMoveNextRequest()}
          wrapperClassName="customLightbox"
        />
      )}
    </div>
  )
}

export default memo(ImageLightbox)
