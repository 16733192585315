import { FC, memo } from 'react'
import categories from './categories.json'
import CategoryTreeFilterWrapper from './categoryTreeFilterWrapper'

interface CategoryTreeFilterProps {
  categoryTree: Array<any>
}

const CategoryTreeFilter: FC<CategoryTreeFilterProps> = ({
  categoryTree
}: CategoryTreeFilterProps) => {
  if (categoryTree[0].label === 'CROWN') {
    const content = categories.map((item, index) => {
      return <CategoryTreeFilterWrapper key={index} categoryTree={[item]} />
    })
    return <>{content}</>
  }

  return <CategoryTreeFilterWrapper categoryTree={categoryTree} />
}

export default memo(CategoryTreeFilter)
