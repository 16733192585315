import { FC, memo } from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'

import {
  ClickAwayListener,
  Grow,
  Icon,
  MenuItem,
  MenuList,
  Paper
} from '@headless/ui'
import { useCategorySort } from '@hooks/CategoryLayout'

import { StyledPopper, StyledSort, StyledTools } from './styled'

interface CategorySortProps {
  id?: number
  q?: string
  sortBy: any
}

const CategorySort: FC<CategorySortProps> = ({ id, q, sortBy }) => {
  const {
    currentLabel,
    sortFields,
    anchorRef,
    open,
    isMobile,
    handleClose,
    handleToggle,
    handleChangeSort,
    handleOpenFilter
  } = useCategorySort({
    id,
    q,
    sortBy
  })

  return (
    <StyledTools>
      {isMobile && (
        <p className="tools" aria-hidden="true" onClick={handleOpenFilter}>
          <i className="icon icon-filter" />
          <span>Filters</span>
        </p>
      )}
      <StyledSort>
        <span className="category__sort" ref={anchorRef} onClick={handleToggle}>
          <span>
            <span className="label">Sort By: &nbsp;</span>
            <span>{currentLabel}</span>
          </span>
          <Icon src={open ? ChevronUp : ChevronDown} />
        </span>
        <StyledPopper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-end"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom'
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open}>
                    {sortFields.map((sort: any) => {
                      return sort.label !== '' ? (
                        <MenuItem
                          key={sort.value}
                          onClick={() => {
                            handleChangeSort(sort)
                          }}
                        >
                          <span
                            dangerouslySetInnerHTML={{ __html: sort.label }}
                          />
                        </MenuItem>
                      ) : null
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </StyledPopper>
      </StyledSort>
    </StyledTools>
  )
}

export default memo(CategorySort)
