import { Container } from '@headless/ui'
import useMediaQuery from '@mui/material/useMediaQuery'
import { FC } from 'react'

import Link from 'next/link'
import CategoryDescription from './CategoryDescription'
import CategoryFilter from './CategoryFilter'
import CategoryList from './CategoryList'
import CategorySort from './CategorySort'
import {
  StyledBanner,
  StyledCategoryLayout,
  StyledLayout,
  StyledToolbar,
  StyledWrapper
} from './styled'

interface CategoryLayoutProps {
  id?: number
  filterInputs: any[]
  q?: string
  search?: string
  filters?: any[]
  aggregations?: any[]
  sortBy?: any
  page?: number
  categories?: any[]
  sortFields?: any[]
}

const CategoryLayout: FC<CategoryLayoutProps> = ({
  id,
  filterInputs,
  q = '',
  search = '',
  filters = [],
  aggregations = [],
  sortBy = {},
  page,
  categories = [],
  sortFields = []
}) => {
  const isMobile: boolean = useMediaQuery('(max-width:767px)')

  const imageSrc = isMobile
    ? 'images/tarot/banner-m.jpg'
    : 'images/tarot/banner.jpg'

  const maybeBanner =
    id === 138 ? (
      <StyledBanner>
        <Link href="/tarot-cards-inner-personality-test">
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img src={imageSrc} alt="tarot" />
        </Link>
      </StyledBanner>
    ) : null

  return (
    <StyledCategoryLayout>
      <Container>
        {maybeBanner}
        <StyledLayout>
          <div className="category__filters">
            <CategoryFilter
              id={id}
              filterInputs={filterInputs}
              q={q}
              filters={filters}
              aggregations={aggregations}
              categories={categories}
              sortFields={sortFields}
            />
          </div>
          <div>
            <StyledToolbar>
              <StyledWrapper>
                <div className="category__name" />
                <div className="category__count" />
              </StyledWrapper>
              <CategorySort id={id} q={q} sortBy={sortBy} />
            </StyledToolbar>
            <CategoryList
              id={id}
              filterInputs={filterInputs}
              q={q}
              search={search}
              page={page}
              filters={filters}
              aggregations={aggregations}
              sortBy={sortBy}
            />
            {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
            {id && <CategoryDescription id={id!} />}
          </div>
        </StyledLayout>
      </Container>
    </StyledCategoryLayout>
  )
}

export default CategoryLayout
