import { FC, memo } from 'react'

import { I18n, Switch } from '@headless/ui'
import { useCategoryFilter } from '@hooks/CategoryLayout'

import CategoryTreeFilter from '../CategoryTreeFilter'
import FeaturedFilter from '../FeaturedFilter'
import CategoryDrawer from './CategoryDrawer'
import CurrentFilters from './CurrentFilters'
import FilterBlock from './FilterBlock'
import FilterCollapse from './FilterCollapse'
import {
  StyledCategoryFilter,
  StyledFilterClearButton,
  StyledFilterContent,
  StyledSwitchContainer
} from './styled'

interface CategoryFilterProps {
  id?: number
  filterInputs: any[]
  q?: string
  filters?: any[]
  aggregations?: any[]
  categories?: any[]
  sortFields?: any[]
}

const CategoryFilter: FC<CategoryFilterProps> = ({
  id,
  filterInputs,
  q,
  filters,
  aggregations,
  categories,
  sortFields
}) => {
  const {
    categoryFilter,
    filterApi,
    filtersData,
    filterState,
    isLoading,
    isMobile,
    showClearAll,
    setIsApplying,
    handleClearAll,
    featuredCategories,
    categoryTree,
    handleInStockChagne,
    inStockChecked
  } = useCategoryFilter({
    id,
    filterInputs,
    q,
    filters,
    aggregations,
    categories,
    sortFields
  })

  const FilterContent = () => {
    const maybeCategoryTreeFilter = categoryTree?.length ? (
      <StyledFilterContent>
        <CategoryTreeFilter categoryTree={categoryTree} />
      </StyledFilterContent>
    ) : null

    const maybeFeaturedCategories = featuredCategories?.length ? (
      <StyledFilterContent>
        <FilterCollapse
          className="filter__collapse"
          filter={{ label: 'Featured' }}
        >
          {featuredCategories.map((category: any) => {
            return <FeaturedFilter category={category} key={category.id} />
          })}
        </FilterCollapse>
      </StyledFilterContent>
    ) : null

    return (
      <>
        <h3 className="filters__title">
          <span>Filters</span>
          {showClearAll && (
            <StyledFilterClearButton onClick={handleClearAll}>
              <I18n id="global.clearAll" />
            </StyledFilterClearButton>
          )}
        </h3>
        {filtersData.length > 0 ? (
          <>
            <CurrentFilters
              filterState={filterState}
              filterApi={filterApi}
              filtersData={filtersData}
              setIsApplying={setIsApplying}
            />
            <StyledSwitchContainer>
              <Switch
                defaultChecked={inStockChecked}
                onChange={handleInStockChagne}
              />
              <span>In Stock</span>
            </StyledSwitchContainer>
            {maybeFeaturedCategories}
            {maybeCategoryTreeFilter}
            <StyledFilterContent>
              {filtersData.map((filter: any) => {
                if (filter.attribute_code === 'category_id') return null
                if (
                  filter.attribute_code === 'quantity_and_stock_status_bucket'
                )
                  return null
                return (
                  <FilterCollapse
                    className="filter__collapse"
                    key={filter.attribute_code}
                    filter={filter}
                  >
                    <FilterBlock
                      key={filter.attribute_code}
                      categoryFilter={categoryFilter}
                      filterState={filterState.get(filter.attribute_code)}
                      items={filter}
                      featuredCategories={featuredCategories}
                      filterApi={filterApi}
                      setIsApplying={setIsApplying}
                    />
                  </FilterCollapse>
                )
              })}
            </StyledFilterContent>
          </>
        ) : (
          <span>
            <I18n id="catalog.noFilter" />
          </span>
        )}
      </>
    )
  }

  return (
    <StyledCategoryFilter>
      {isLoading ? (
        <div />
      ) : (
        <>
          {isMobile ? (
            <>
              <CategoryDrawer>
                <FilterContent />
              </CategoryDrawer>
            </>
          ) : (
            <FilterContent />
          )}
        </>
      )}
    </StyledCategoryFilter>
  )
}

export default memo(CategoryFilter)
