import { FC, memo, useState } from 'react'
import { clsx } from 'clsx'

import { Collapse } from '@headless/ui'

import { StyledCollapse } from './styled'

interface FilterCollapseProps {
  className?: string
  children: any
  filter: any
}

const FilterCollapse: FC<FilterCollapseProps> = ({
  className,
  children,
  filter
}) => {
  const [opened, setOpened] = useState<boolean>(true)

  const handleClick = () => {
    setOpened((prevState: any) => !prevState)
  }

  return (
    <StyledCollapse className={className}>
      <p
        className={clsx({
          tab__title: true,
          active: opened
        })}
        aria-hidden="true"
        onClick={handleClick}
      >
        <span dangerouslySetInnerHTML={{ __html: filter.label }} />
      </p>
      <Collapse in={opened}>{children}</Collapse>
    </StyledCollapse>
  )
}

export default memo(FilterCollapse)
