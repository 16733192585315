import styled from '@emotion/styled'

export const StyledProductItem = styled.div`
  position: relative;
  .product__price--crossed {
    font-size: 0.65rem;
  }

  .videoMark {
    position: absolute;
    right: 0;
    top: 0;
    background: url('/images/category/video-mark.png') no-repeat;
    background-size: contain;
    width: 29px;
    height: 28px;
    z-index: 1;
  }

  .newMark {
    position: absolute;
    left: -2px;
    top: 10px;
    background: url('/images/category/new-mark.png') center no-repeat;
    background-size: contain;
    width: 47px;
    height: 22px;
    z-index: 1;
  }

  .bestSellerMark {
    position: absolute;
    left: -2px;
    top: 10px;
    background: url('/images/category/best-seller-mark.png') center no-repeat;
    background-size: contain;
    width: 89px;
    height: 22px;
    z-index: 1;
  }

  .preOrderMark {
    position: absolute;
    left: -2px;
    top: 10px;
    background: url('/images/category/pre-order-mark.png') center no-repeat;
    background-size: contain;
    width: 89px;
    height: 22px;
    z-index: 1;
  }

  .soldOutMark {
    position: absolute;
    left: -2px;
    top: 10px;
    background: url('/images/category/sold-out.png') center no-repeat;
    background-size: contain;
    width: 73px;
    height: 22px;
    z-index: 1;
  }

  .sale {
    position: absolute;
    left: -2px;
    top: 10px;
    background: url('/images/category/sale-mark.png') center no-repeat;
    background-size: contain;
    width: 59px;
    height: 22px;
    z-index: 1;
  }

  @media screen and (max-width: 767px) {
    .count {
      .label {
        display: none;
      }
    }
  }

  .slick-dots {
    bottom: 0.2rem !important;

    > li {
      padding-right: 10px !important;

      button {
        &::before {
          width: 20px !important;
          height: 2px !important;
          transition: all 0.3s !important;
          border-radius: 0 !important;
          background-color: #fff !important;
        }
      }

      &.slick-active {
        button {
          &::before {
            width: 25px !important;
            height: 2px !important;
            transition: all 0.3s !important;
            border-radius: 0 !important;
            background-color: #f2e0d6 !important;
          }
        }
      }
    }
  }
`

export const StyledProductImg = styled.div`
  position: relative;
  overflow: hidden;

  .product__image {
    &--container {
      display: block;
      max-width: 100%;
    }

    &--wrapper {
      display: block;
      height: 0;
      overflow: hidden;
      position: relative;
      z-index: 1;
      background: url('images/placeholder.png') top center no-repeat;
      background-size: cover;
    }

    &--photo {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      margin: auto;
      width: auto;

      &--hover {
        opacity: 0;
        visibility: hidden;
        transition: all 500ms;
      }
    }
  }

  .MuiLink-root {
    display: block;

    @media screen and (min-width: 767px) {
      &:hover {
        .product__image {
          &--photo {
            &--hover {
              opacity: 1;
              visibility: visible;
              transform: translateY(1);
            }
          }
        }
      }
    }
  }
`

export const StyledProductName = styled.p`
  margin-block-end: 0.2rem;

  > a {
    display: block;
    color: #52575d;
    font-size: 0.875rem;
    font-weight: 500;
    text-decoration: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
      color: #dbb19f;
    }
  }
`

export const StyledProductAddToCart = styled.div`
  margin-top: 12px;
  position: absolute;
  left: 25%;
  z-index: 2;
  width: 50%;
  height: 40px;
  bottom: 15%;
  > button {
    width: 100%;
    height: 100%;
    background-color: #fff;
    color: #212529;
    font-family: 'karla';
    text-transform: uppercase;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
    border: none;
    /* transform: translate(50%, 650%); */
    border-radius: 0px;
    :hover {
      cursor: pointer;
      background-color: #f2e0d6;
      transition: all 0.3s ease-in-out;
    }
  }

  @media screen and (max-width: 767px) {
    position: relative;
    width: 100%;
    height: 30px;
    > button {
      position: relative;
      background-color: #212529;
      color: #fff;
      transform: translate(0, 0);
    }
  }
`

export const StyledProductAddToCartMobile = styled.div`
  position: absolute;
  right: 0px;
  bottom: 0px;
  height: 22px;
  width: 22px;
`

export const StyledShimmerRoot = styled.div``
