import useMediaQuery from '@mui/material/useMediaQuery'
import { FC, memo, useState } from 'react'
import { Minus, Plus } from 'react-feather'

import { Button, I18n, Icon, InputAdornment, LoadingButton } from '@headless/ui'
import { useCatalogActions } from '@hooks/CatalogPage'

import BundleOptions from '@components/BundleOptions'
import { clsx } from 'clsx'
import { useRouter } from 'next/router'
import ConfigurableOptions from '../ConfigurableOptions'
import {
  StyleText,
  StyledAddToCartSuccess,
  StyledButton,
  StyledCatalogActions,
  StyledPreOrderButton,
  StyledStock,
  StyledTextField
} from './styled'

interface ProductProps {
  product: any
  currenct: any
}

const CatalogActions: FC<ProductProps> = ({ product, currenct }) => {
  const {
    control,
    errors,
    loading,
    register,
    handleQtyMinus,
    handleQtyPlus,
    handleQtyChange,
    handleFormSubmit,
    handleOpenNotify,
    qty,
    isShowMiniTips
  } = useCatalogActions({ product, currenct })
  const router = useRouter()

  const { __typename } = product
  const options: any[] = product?.configurable_options ?? []
  const variants: any[] = product?.variants ?? []
  const isConfigurable: boolean = __typename === 'ConfigurableProduct'

  const [isPreSalePc, setIsPreSalePc] = useState(false)

  const isPreSale = product?.presale_product?.status || isPreSalePc

  const maybePreSale = (
    <>
      <StyledTextField
        type="number"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Icon src={Minus} size={18} onClick={handleQtyMinus} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <Icon src={Plus} size={18} onClick={handleQtyPlus} />
            </InputAdornment>
          )
        }}
        required
        {...register('quantity')}
        onChange={handleQtyChange}
      />
      <StyleText>
        {product?.presale_product?.note ||
          'THIS STYLE IS EXPECTED TO SHIP IN A MONTH'}
      </StyleText>
      <StyledPreOrderButton>
        <LoadingButton
          type="submit"
          variant="contained"
          size="large"
          fullWidth
          loading={loading}
          loadingIndicator={<I18n id="global.adding" />}
        >
          {product?.presale_product?.label || 'PRE-ORDER'}
        </LoadingButton>
      </StyledPreOrderButton>
    </>
  )

  const maybeInStock = (
    <>
      <StyledTextField
        type="number"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Icon src={Minus} size={18} onClick={handleQtyMinus} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <Icon src={Plus} size={18} onClick={handleQtyPlus} />
            </InputAdornment>
          )
        }}
        required
        {...register('quantity')}
        onChange={handleQtyChange}
      />
      <StyledButton>
        <LoadingButton type="submit" variant="contained" size="large" fullWidth>
          {loading ? (
            <I18n id="global.adding" />
          ) : (
            <I18n id="global.addToCart" />
          )}
        </LoadingButton>
      </StyledButton>
    </>
  )

  const maybeOutOfStock = (
    <>
      <StyledStock>
        <p>Out Of Stock</p>
        <Button
          type="button"
          variant="contained"
          fullWidth
          onClick={handleOpenNotify}
        >
          Get On The Waitlist
        </Button>
      </StyledStock>
    </>
  )

  const isMobile: boolean = useMediaQuery('(max-width:767px)')

  return (
    <StyledCatalogActions>
      <BundleOptions />
      <form onSubmit={handleFormSubmit}>
        {isConfigurable && (
          <ConfigurableOptions
            control={control}
            errors={errors}
            options={options}
            variants={variants}
            setIsPreSalePc={setIsPreSalePc}
          />
        )}
        {/* eslint-disable-next-line no-nested-ternary */}
        {isPreSale
          ? maybePreSale
          : currenct.stock === 'IN_STOCK'
          ? maybeInStock
          : maybeOutOfStock}
      </form>

      {isMobile ? (
        <StyledAddToCartSuccess
          className={clsx({
            open: isShowMiniTips
          })}
        >
          <div>
            <p>{qty || 0} item added to your cart</p>
            {/* <Button onClick={() => router.push('/checkout')}>CHECKOUT</Button> */}

            <StyledButton>
              <LoadingButton
                // type="submit"
                variant="contained"
                size="large"
                fullWidth
                onClick={() => router.push('/checkout')}
              >
                CHECKOUT
              </LoadingButton>
            </StyledButton>
          </div>
        </StyledAddToCartSuccess>
      ) : null}
    </StyledCatalogActions>
  )
}

export default memo(CatalogActions)
