import { Image, VideoPlayer } from '@headless/ui'

import { useImageSwiper } from '@hooks/CategoriesAddToCartDialog/ImageSwiper'
import { FC, memo } from 'react'
import 'swiper/css'
import { Swiper, SwiperSlide } from 'swiper/react'
import { StyledSwiper } from './styled'

interface ImageSwiperProps {
  image: any
  gallery: any[]
  videoUrl: string
}

interface imageObj {
  label?: string
  original: string
  thumbnail: string
  video?: boolean
  image?: string
  video_url?: string
}

const ImageSwiper: FC<ImageSwiperProps> = ({ image, gallery, videoUrl }) => {
  const { images } = useImageSwiper({
    image,
    gallery,
    videoUrl
  })

  return (
    <StyledSwiper>
      <Swiper
        slidesPerView={1.4}
        spaceBetween={6}
        centeredSlides
        loop
        navigation
        className="mySwiper"
      >
        {images.map((item: imageObj, index) => {
          return (
            <SwiperSlide key={index}>
              {item.video ? (
                <VideoPlayer
                  url={videoUrl.replace('www', 'magento')}
                  controls
                  height="100%"
                  className="videoPlayer"
                />
              ) : (
                <Image src={item.original} />
              )}
            </SwiperSlide>
          )
        })}
      </Swiper>
    </StyledSwiper>
  )
}

export default memo(ImageSwiper)
