import { DocumentNode, gql } from '@apollo/client'

export const GET_ALL_CATEGORIES: DocumentNode = gql`
  query getAllCategoriesQuery {
    allCategories {
      items {
        id
        uid
        name
        url_key
        is_featured
      }
      aggregations {
        code
        option_id
        rewrite
      }
      sort_fields {
        default
        options {
          label
          value
        }
      }
    }
  }
`
