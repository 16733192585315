import { FC, memo } from 'react'

import FilterItem from '../FilterItem'
import { StyledFilterBlock } from './styled'

interface FilterBlockProps {
  filterState: any
  items: any
  categoryFilter: any
  filterApi: any
  setIsApplying: any
  featuredCategories: any
}

const FilterBlock: FC<FilterBlockProps> = ({
  filterState = new Set(),
  categoryFilter,
  items,
  filterApi,
  setIsApplying,
  featuredCategories
}) => {
  const { attribute_code: group, options, count } = items

  return (
    <StyledFilterBlock>
      {options.map((option: any) => {
        if (
          group === 'category_id' &&
          featuredCategories.find(
            (temp: any) => temp.id === parseInt(option.value, 10)
          )
        ) {
          return null
        }
        const { label, value } = option
        const key = `${group}-${label}-${value}`

        return (
          <FilterItem
            key={key}
            filterState={filterState}
            filterApi={filterApi}
            categoryFilter={categoryFilter}
            item={option}
            group={group}
            count={count}
            setIsApplying={setIsApplying}
          />
        )
      })}
    </StyledFilterBlock>
  )
}

export default memo(FilterBlock)
