/* eslint-disable jsx-a11y/media-has-caption */
import ImageGallery from '@components/ReactImageGallery'
import { FC, useEffect, useRef, useState } from 'react'
import { MOUSE_ACTIVATION } from 'react-input-position'
import Magnifier from '../Magnifier'
import { RenderItem, StyledGallery } from './styled'

interface GalleryProps {
  images: any[]
  lazyLoad?: boolean
  showNav?: boolean
  showThumbnails?: boolean
  showPlayButton?: boolean
  thumbnailPosition?: string
  productName?: string
  productSku?: string
}

const Gallery: FC<GalleryProps> = ({
  images,
  lazyLoad = true,
  showNav = true,
  showThumbnails = true,
  showPlayButton = false,
  thumbnailPosition = 'left',
  productName = '',
  productSku = '',
  ...props
}) => {
  const [galleryThumbnailPosition, setGalleryThumbnailPosition] =
    useState<any>('bottom')

  const [videoPlaying, setVideoPlaying] = useState<boolean>(false)

  const videoRef = useRef<any>(null)

  // Set gallery thumbnail position
  useEffect(() => {
    if (images.length > 0) {
      setTimeout(() => {
        setGalleryThumbnailPosition(thumbnailPosition)
      }, 18)
    }
  }, [images.length, thumbnailPosition])

  useEffect(() => {
    if (videoPlaying) {
      videoRef.current?.play()
    } else {
      videoRef.current?.pause()
    }
  }, [videoPlaying])

  const renderItem = (item: any) => {
    return (
      <RenderItem className="render__item">
        {item.video ? (
          <div className="video-gallery-image">
            <video
              ref={videoRef}
              loop
              controls
              title={`${productName} for ${productSku}`}
              style={{ width: '100%', height: '100%' }}
              width={1125}
              height={1500}
            >
              <source
                src={item.video_url.replace('www', 'magento')}
                type="video/mp4"
              />
            </video>
          </div>
        ) : (
          <>
            <picture>
              <img
                className="image-gallery-image"
                src={item.original}
                alt={`${productName} for ${productSku}`}
              />
            </picture>
            <Magnifier
              type="magnifier"
              className="image-magnifier"
              imageSrc={item.original}
              largeImageSrc={item.original}
              mouseActivation={MOUSE_ACTIVATION.HOVER}
              allowOverflow
              dragToMove={false}
              productName={productName}
              productSku={productSku}
            />
          </>
        )}
      </RenderItem>
    )
  }

  return (
    <StyledGallery>
      <ImageGallery
        items={images}
        lazyLoad={lazyLoad}
        showNav={showNav}
        showThumbnails={showThumbnails}
        showPlayButton={showPlayButton}
        renderItem={renderItem}
        productName={productName}
        productSku={productSku}
        thumbnailPosition={galleryThumbnailPosition}
        onThumbnailClick={(e: MouseEvent, index: number) => {
          if (index === 1) {
            setVideoPlaying(true)
          } else {
            setVideoPlaying(false)
          }
        }}
        {...props}
      />
    </StyledGallery>
  )
}

export default Gallery
