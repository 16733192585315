import { useQuery } from '@apollo/client'

import { GET_CATEGORY_DETAIL } from '@graphql/queries/getCategoryDetail'
import { useRouter } from 'next/router'

import { useEffect, useState } from 'react'

export const useCategoryDetail = (id: any) => {
  const router = useRouter()

  const { asPath } = router

  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    if (asPath) {
      let temp = 1
      const staticPaths = asPath.split('/')

      const filtersPaths = staticPaths.slice(2)

      if (filtersPaths.length > 0) {
        const index = filtersPaths.findIndex((item: string) => item === 'p')
        // 明确使用十进制
        if (
          index > -1 &&
          !Number.isNaN(parseInt(filtersPaths[index + 1], 10))
        ) {
          temp = parseInt(filtersPaths[index + 1], 10)
        }
      }

      temp = temp && temp > 0 ? temp : 1

      setCurrentPage(temp)
    }
  }, [asPath])

  const { data } = useQuery(GET_CATEGORY_DETAIL, {
    variables: {
      filters: {
        ids: {
          eq: id
        }
      },
      currentPage
    }
  })

  const category = data?.categoryList?.[0] ?? {}

  const seoRichSnippets = category?.seo_rich_snippets

  return {
    category,
    seoRichSnippets
  }
}
