import styled from '@emotion/styled'

export const StyledMedia = styled.div`
  padding-right: 1rem;
  min-height: 31.25rem;

  .image-gallery-left-nav,
  .image-gallery-right-nav {
    opacity: 0;
    visibility: hidden;
  }
`

export const StyledCarousel = styled.div`
  .slick-slider {
    .slick-dots {
      bottom: 1rem;

      > li {
        width: 0.5rem;
        height: 0.5rem;
        margin: 0 0.25rem;

        button {
          &:before {
            width: 0.5rem;
            height: 0.5rem;
            background-color: #7d7d7d;
          }
        }
      }
    }
  }
`

export const StyleVideoPlayer = styled.div`
  .videoPlayer {
    padding-bottom: 133.25%;
    position: relative;

    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`
