export const getParamsFormQuery = (
  initialValue: any,
  filterKeys: any[] = []
) => {
  let str = ''

  for (const group of Object.keys(initialValue)) {
    if (!filterKeys.includes(group)) {
      const values = initialValue[group]
      if (Array.isArray(values)) {
        for (const item of values) {
          str += `&${group}=${item.replace(/\&/g, '%26')}`
        }
      } else {
        str += `&${group}=${values.replace(/\&/g, '%26')}`
      }
    }
  }

  return `?${str.slice(1)}`
}
