import { useCatalogActions } from './CatalogActions'
import { useCatalogMedia } from './CatalogMedia'
import { useCatalogRelated } from './CatalogRelated'
import { useCatalogReviews } from './CatalogReviews'
import { useCategoryPage } from './useCategoryPage'

export {
  useCatalogActions,
  useCatalogMedia,
  useCatalogRelated,
  useCatalogReviews,
  useCategoryPage
}
