import CatalogActions from '@components/CatalogPage/CatalogActions'
import CatalogMedia from '@components/CatalogPage/CatalogMedia'
import CatalogNotify from '@components/CatalogPage/CatalogNotify'
import ProductPrice from '@components/ProductPrice'
import { Dialog, Link, NoSsr } from '@headless/ui'
import { event } from '@headless/utils'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import useMediaQuery from '@mui/material/useMediaQuery'
import { FC, memo, useEffect, useState } from 'react'
import ImageSwiper from './ImageSwiper'
import {
  StyledCatalogMainDialog,
  StyledCategoriesAddToCartDialog
} from './styled'

interface ProductProps {
  product: any
  handleCloseAddToCartDialog?: () => void
  addToCartDialogOpen: boolean
}

const CategoriesAddToCartDialog: FC<ProductProps> = ({
  handleCloseAddToCartDialog,
  addToCartDialogOpen,
  product
}) => {
  const { main_image, media_gallery, price_range, sku, stock_status, name } =
    product || {}
  const [currenct, setCurrenct] = useState<any>({
    image: main_image,
    gallery: media_gallery,
    price: price_range,
    stock: stock_status,
    sku,
    option: {}
  })

  const isMobile: boolean = useMediaQuery('(max-width:767px)')
  const isPC: boolean = !isMobile

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    window.dataLayer && window.dataLayer.push({ ecommerce: null })
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    window.dataLayer &&
      window.dataLayer.push({
        event: 'select_item',
        ecommerce: {
          items: [
            {
              item_name: name,
              item_id: sku,
              price:
                price_range?.minimum_price?.final_price?.value ||
                price_range?.maximum_price?.final_price?.value,
              quantity: 1
            }
          ]
        }
      })
  }, [
    name,
    price_range?.maximum_price?.final_price?.value,
    price_range?.minimum_price?.final_price?.value,
    sku
  ])

  useEffect(() => {
    setCurrenct({
      image: main_image,
      gallery: media_gallery,
      price: price_range,
      stock: stock_status,
      sku,
      option: {}
    })
  }, [main_image, media_gallery, price_range, sku, stock_status])

  useEffect(() => {
    const handleSelect = (params: any) => {
      setCurrenct({ ...params })
    }

    event.on('updateProductItem', handleSelect)

    return () => {
      event.off('updateProductItem', handleSelect)
    }
  }, [])

  useEffect(() => {
    if (product) {
      try {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        window.dataLayer && window.dataLayer.push({ ecommerce: null })
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        window.dataLayer &&
          window.dataLayer.push({
            event: 'view_item',
            ecommerce: {
              currency: currenct?.price?.minimum_price?.final_price?.currency,
              value: currenct?.price?.minimum_price?.final_price?.value,
              items: [
                {
                  item_name: product.name,
                  item_id: product.uid,
                  price: currenct?.price?.minimum_price?.final_price?.value,
                  quantity: 1
                }
              ]
            }
          })
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err)
      }
    }
  }, [product, currenct.price])

  return (
    <StyledCategoriesAddToCartDialog>
      <Dialog
        open={addToCartDialogOpen}
        onClose={handleCloseAddToCartDialog}
        maxWidth={false}
        className="AddToCartDialog"
        fullScreen={!!isMobile}
      >
        <StyledCatalogMainDialog>
          <IconButton
            aria-label="close"
            onClick={handleCloseAddToCartDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
          {isPC ? (
            <div>
              <NoSsr>
                <CatalogMedia
                  image={currenct.image}
                  gallery={currenct.gallery}
                  videoUrl={product?.video_url}
                />
              </NoSsr>
            </div>
          ) : (
            <ImageSwiper
              gallery={currenct.gallery}
              videoUrl={product?.video_url}
              image={currenct.image}
            />
          )}
          <div className="product__main">
            <h1
              className="product__title"
              dangerouslySetInnerHTML={{ __html: product.name }}
            />
            <ProductPrice className="product__price" {...currenct.price} />
            <CatalogActions product={product} currenct={currenct} />
            {currenct.stock === 'OUT_OF_STOCK' && (
              <CatalogNotify id={product.id} sku={product.sku} />
            )}
            <Link
              className="view_full_details"
              href={`${product?.url_key}.html`}
            >
              View Full Details
            </Link>
          </div>
        </StyledCatalogMainDialog>
      </Dialog>
    </StyledCategoriesAddToCartDialog>
  )
}

export default memo(CategoriesAddToCartDialog)
