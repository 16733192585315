import { orderBy } from 'lodash'
import { useMemo } from 'react'

interface ImageSwiperProps {
  image: any
  gallery: any[]
  videoUrl: string
}

export const useImageSwiper = (props: ImageSwiperProps) => {
  const { image, gallery, videoUrl } = props || { gallery: [] }
  const images = useMemo(() => {
    const videoUrlClone = videoUrl?.replace('pwa', 'magento')
    const result: any[] = []

    const originalPictureList = orderBy(gallery, 'position') || []

    originalPictureList.forEach((item: any, index: number) => {
      if (index === 1 && videoUrl) {
        result.push({
          label: 'Video',
          original: originalPictureList[1]?.image ?? '',
          thumbnail: originalPictureList[1]?.image ?? '',
          image: videoUrlClone,
          video: true,
          video_url: videoUrlClone
        })
        return
      }
      if (!item.disabled)
        result.push({
          label: item.label,
          original: item.image,
          thumbnail: item.thumbnail,
          video: item?.video_content ?? null
        })
    })

    if (gallery.length === 0) {
      result.push({
        label: image?.label ?? '',
        original: image?.url ?? '',
        thumbnail: image?.url ?? '',
        video: null
      })
    }

    return result
  }, [image, gallery, videoUrl])

  return {
    images
  }
}
