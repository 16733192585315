import { FC, memo, useState } from 'react'

import { Carousel, Gallery, Image, VideoPlayer } from '@headless/ui'
import { useCatalogMedia } from '@hooks/CatalogPage'

import ImageLightbox from '@components/ImageLightbox'
import { StyledCarousel, StyledMedia, StyleVideoPlayer } from './styled'

interface CatalogMediaProps {
  image: any
  gallery?: any[]
  videoUrl: string
}

const CatalogMedia: FC<CatalogMediaProps> = ({
  image,
  gallery = [],
  videoUrl
}) => {
  const { images, isMobile, thumbnailImages } = useCatalogMedia({
    image,
    gallery,
    videoUrl
  })

  const [index, setIndex] = useState<number>(0)
  const [isOpen, setisOpen] = useState<boolean>(false)

  const handleOpenLight = (url: string) => {
    const match: any = thumbnailImages.findIndex((temp) => temp === url)
    setIndex(match)
    setisOpen(true)
  }

  const onCloseRequest = () => {
    setisOpen(false)
  }
  const onMovePrevRequest = () => {
    setIndex((prev: any) => prev - 1)
  }
  const onMoveNextRequest = () => {
    setIndex((prev: any) => prev + 1)
  }

  return (
    <>
      {isMobile ? (
        <StyledCarousel>
          <Carousel slidesToShow={1} arrows={false}>
            {images.map((img: any) => {
              return (
                <div key={`image__${Math.random()}`}>
                  {img.video ? (
                    <StyleVideoPlayer>
                      <VideoPlayer
                        url={videoUrl.replace('www', 'magento')}
                        controls
                        muted
                        height="100%"
                        playsinline
                        className="videoPlayer"
                      />
                    </StyleVideoPlayer>
                  ) : (
                    <Image
                      src={img.original}
                      alt={img.label}
                      onClick={() => {
                        handleOpenLight(img.original)
                      }}
                    />
                  )}
                </div>
              )
            })}
          </Carousel>
          <ImageLightbox
            images={thumbnailImages}
            index={index}
            isOpen={isOpen}
            onCloseRequest={onCloseRequest}
            onMovePrevRequest={onMovePrevRequest}
            onMoveNextRequest={onMoveNextRequest}
          />
        </StyledCarousel>
      ) : (
        <StyledMedia>
          <Gallery images={images} thumbnailPosition="left" />
        </StyledMedia>
      )}
    </>
  )
}

export default memo(CatalogMedia)
