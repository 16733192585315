import { DocumentNode, gql } from '@apollo/client'
import { categoryProduct } from '../fragment/categoryProduct'
import { price_range } from '../fragment/priceRange'
import { price_tiers } from '../fragment/priceTiers'

export const GET_PRODUCT_UPSELL: DocumentNode = gql`
  query getUpsellProduct($filter: ProductAttributeFilterInput) {
    products(filter: $filter) {
      items {
        upsell: upsell_products {
          id
          sku
          name
          url_key
          uid
          small_image {
            label
            url(width: 384, height: 512)
          }
          main_image: image {
            label
            url
          }
          video_url
          media_gallery {
            disabled
            label
            position
            thumbnail: url(type: "thumbnail", width: 120, height: 160)
            image: url(type: "image", width: 1125, height: 1500)
            # thumbnail: url(width: 1125, height: 1500)
            ... on ProductVideo {
              video_content {
                media_type
                video_provider
                video_url
                video_title
                video_description
                video_metadata
              }
            }
            __typename
          }
          ...categoryProduct
          price_range {
            ...price_range
            __typename
          }
          price_tiers {
            ...price_tiers
            __typename
          }
          review_count
          rating_summary
          presale_product {
            status
            label
            note
          }
          product_mark
          review_count
          stock_status
          hover_image(width: 384, height: 512)
          __typename
        }
      }
    }
  }
  ${price_range}
  ${categoryProduct}
  ${price_tiers}
`
