import { FC, ChangeEvent } from 'react'
import { Controller, FieldErrors } from 'react-hook-form'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox'

import { formatMessage } from '../CurrentLocale'

interface BaseCheckboxProps extends CheckboxProps {
  name: string
  label?: string
  required?: boolean
  validate?: any
  control: any
  errors: FieldErrors
  setValue: Function
}

const BaseCheckbox: FC<BaseCheckboxProps> = ({
  control,
  errors,
  name,
  label,
  required,
  validate,
  setValue,
  ...rest
}) => {
  const { onChange } = rest
  const controllerProps: any = {
    type: 'checkbox',
    name,
    key: name,
    rules: {
      required: required && formatMessage({ id: 'global.required' }),
      validate
    },
    control
  }

  const checkboxFiled: any = {
    name,
    key: name,
    required,
    ...rest
  }

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(name, e.target.checked)
    if (onChange) onChange(e, e.target.checked)
  }

  return (
    <FormControl>
      <Controller
        {...controllerProps}
        render={({ field }) => {
          return (
            <FormControlLabel
              control={
                <Checkbox
                  {...field}
                  {...checkboxFiled}
                  onChange={handleOnChange}
                />
              }
              label={label}
              checked={field.value}
            />
          )
        }}
      />
      {errors[name] && (
        <FormHelperText error={!!errors[name]}>
          {(errors as any)[name] ? (errors as any)[name].message : null}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default BaseCheckbox
