import { useCallback, useMemo, useReducer } from 'react'

const init = (next?: any) => (next instanceof Map ? next : new Map())

const reducer = (state: any, action: any) => {
  const { payload, type } = action

  switch (type) {
    case 'clear': {
      return init()
    }
    case 'add item': {
      const { group, item } = payload
      const nextState = new Map(state)
      const nextSet = new Set(state.get(group))

      nextSet.add(item)
      nextState.set(group, nextSet)

      return nextState
    }

    case 'delete group': {
      const { group } = payload
      const nextState = new Map(state)

      console.log({
        group,
        nextState
      })

      nextState.delete(group)

      console.log({
        nextState
      })

      return nextState
    }
    case 'remove item': {
      const { group, item } = payload
      const nextState = new Map(state)
      const nextSet = new Set(state.get(group))

      nextSet.delete(item)

      if (nextSet.size) {
        nextState.set(group, nextSet)
      } else {
        nextState.delete(group)
      }

      return nextState
    }
    case 'toggle item': {
      const { group, item } = payload
      const nextState = new Map(state)
      const nextSet = new Set(state.get(group))

      if (nextSet.has(item)) {
        nextSet.delete(item)
      } else {
        // Single choose filter
        nextSet.clear()
        nextSet.add(item)
      }

      if (nextSet.size) {
        nextState.set(group, nextSet)
      } else {
        nextState.delete(group)
      }

      return nextState
    }
    case 'set items': {
      return init(payload)
    }
    default:
      return init()
  }
}

export const useFilter = () => {
  const [state, dispatch] = useReducer(reducer, null, init)

  const addItem = useCallback(
    (payload: any) => {
      dispatch({ payload, type: 'add item' })
    },
    [dispatch]
  )

  const clear = useCallback(
    (payload?: any) => {
      dispatch({ payload, type: 'clear' })
    },
    [dispatch]
  )

  const removeItem = useCallback(
    (payload: any) => {
      dispatch({ payload, type: 'remove item' })
    },
    [dispatch]
  )

  const setItems = useCallback(
    (payload: any) => {
      dispatch({ payload, type: 'set items' })
    },
    [dispatch]
  )

  const toggleItem = useCallback(
    (payload: any) => {
      dispatch({ payload, type: 'toggle item' })
    },
    [dispatch]
  )

  const deleteGroup = useCallback(
    (payload: any) => {
      dispatch({ payload, type: 'delete group' })
    },
    [dispatch]
  )

  const api = useMemo(
    () => ({
      addItem,
      clear,
      dispatch,
      removeItem,
      deleteGroup,
      setItems,
      toggleItem
    }),
    [addItem, clear, dispatch, removeItem, setItems, toggleItem, deleteGroup]
  )

  return [state, api]
}
