import useMediaQuery from '@mui/material/useMediaQuery'
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useSelector } from 'react-redux'

import { GET_SORT_FIELDS } from '@graphql/queries/getSortFields'
import { useAwaitQuery, useSort } from '@headless/hooks'
import { event } from '@headless/utils'

interface CategorySortProps {
  id?: number
  q?: string
  sortBy: any
}

export const useCategorySort = ({ id, q, sortBy }: CategorySortProps) => {
  const anchorRef = useRef<HTMLElement>(null)
  const sortFieldQuery: any = useAwaitQuery(GET_SORT_FIELDS)
  const storeConfig = useSelector((state: any) => state.app.storeConfig)
  const [sortState, sortApi]: any = useSort(
    sortBy?.value || storeConfig.sort_by
  )
  const [sortFields, setSortFields] = useState<any>([])
  const [open, setOpen] = useState<boolean>(false)
  const isMobile: boolean = useMediaQuery('(max-width:767px)')

  const currentLabel = useMemo(() => {
    const currentSort = sortFields.filter(
      (sort: any) => sort.value === sortState.sortCode
    )

    return currentSort.length ? currentSort[0].label : 'Loading...'
  }, [sortFields, sortState.sortCode])

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (e: Event | SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(e.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }

  const handleChangeSort = useCallback(
    (params: any) => {
      setOpen(false)
      sortApi.setSort(params.value)
    },
    [sortApi]
  )

  const handleOpenFilter = () => {
    event.emit('handleOpenFilter', true)
  }

  useEffect(() => {
    const fetchSortQuery = async () => {
      const filters: any = { category_id: { eq: id } }
      const variables: any = q ? { search: q } : { filters }

      const { data } = await sortFieldQuery({
        variables
      })

      const fields: any[] = data?.productSort?.sort_fields?.options ?? []
      const result: any[] = fields.filter(
        (field: any) => !['price', 'name'].includes(field.value)
      )
      setSortFields(result)

      // if (result.length > 0) {
      //   sortApi.setSort(result[0].value)
      // }
    }

    fetchSortQuery()
  }, [id, q, sortFieldQuery])

  return {
    currentLabel,
    sortFields,
    anchorRef,
    open,
    isMobile,
    handleClose,
    handleToggle,
    handleChangeSort,
    handleOpenFilter
  }
}
