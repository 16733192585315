import useMediaQuery from '@mui/material/useMediaQuery'
import { StyledProductImg, StyledProductItem } from './styled'

const ProductItemShimmer = () => {
  const isMobile: boolean = useMediaQuery('(max-width:767px)')

  return (
    <StyledProductItem>
      <StyledProductImg>
        <span className="product__image--container">
          <picture
            className="product__image--wrapper"
            style={{
              paddingBottom: `${(isMobile ? 230 / 175 : 360 / 265) * 100}%`
            }}
          >
            <img src="images/placeholder.png" alt="" />
          </picture>
        </span>
      </StyledProductImg>
    </StyledProductItem>
  )
}

export default ProductItemShimmer
