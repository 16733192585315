import styled from '@emotion/styled'

export const StyledDescription = styled.div`
  .description {
    &__title {
      text-align: center;
      font-size: 1.25rem;
      color: #333;
      letter-spacing: 0.075rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 30px;
      margin-top: 30px;
      border-top: 1px #dedfe0 solid;
      font-family: 'Burgess';
      cursor: pointer;
    }

    &__titleIcon {
      display: inline-block;
      width: 18px;
      height: 18px;
      margin-left: 5px;
      background: url('/images/chevron-down.svg') center no-repeat;
    }

    &__titleIcon.collapsed {
      transform: rotate(180deg);
    }

    &__content {
      font-size: 1rem;
      letter-spacing: 0.015rem;
      color: #615956;
      display: flex;
      margin-top: 1.5rem;
      justify-content: flex-end;
      flex-direction: column;
    }

    &__content > * {
      font-size: 0.875rem;
      font-family: 'RobotoRegular';
      letter-spacing: 0.2px;
      color: #666;
      font-weight: 500 !important;
    }

    &__content p {
      margin-bottom: 1rem;
    }

    &__content a {
      color: #999;
      text-decoration: underline;
    }
  }
`
