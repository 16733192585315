import { useQuery } from '@apollo/client'
import { GET_PRODUCT_DETAIL } from '@graphql/queries/getProductDetail'
import { actions as userActions } from '@store/user'
import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'

// We only want to display breadcrumbs for one category on a PDP even if a
// product has multiple related categories. This function filters and selects
// one category id for that purpose.
const getBreadcrumbCategoryId = (
  categories: Array<{
    uid: string
    breadcrumbs: Array<{
      category_uid: string
    }>
  }>
) => {
  // Exit if there are no categories for this product.
  if (!categories || !categories.length) {
    return
  }
  const breadcrumbSet = new Set()
  categories.forEach(({ breadcrumbs }) => {
    // breadcrumbs can be `null`...
    ;(breadcrumbs || []).forEach(({ category_uid }) =>
      breadcrumbSet.add(category_uid)
    )
  })

  // Until we can get the single canonical breadcrumb path to a product we
  // will just return the first category id of the potential leaf categories.
  const leafCategory = categories.find(
    (category) => !breadcrumbSet.has(category.uid)
  )

  // If we couldn't find a leaf category then just use the first category
  // in the list for this product.
  return leafCategory?.uid || categories[0].uid
}

export const useCategoryPage = (url: string) => {
  // 开发时候不要缓存
  const isProduction = process.env.NODE_ENV === 'production'

  const dispatch = useDispatch()

  const fetchPolicy = isProduction ? 'cache-and-network' : 'no-cache'

  const { data } = useQuery(GET_PRODUCT_DETAIL, {
    variables: {
      filter: {
        url_key: {
          eq: url
        }
      }
    },
    fetchPolicy
  })

  const product = useMemo(() => {
    return data?.products?.items?.[0] ?? {}
  }, [data?.products?.items])

  const bundleItemsOptions: any = useMemo(() => {
    const { items } = product || {}

    if (!items) return []

    const { options: optionsBundle } = items[0] || {}

    if (!optionsBundle) return []

    return { options: optionsBundle, items }
  }, [product])

  useEffect(() => {
    const setbundleItemsOptions = async () => {
      await dispatch(userActions.setBundleItemsOptions(bundleItemsOptions))
    }

    setbundleItemsOptions()
  }, [bundleItemsOptions, dispatch])

  const { options } = bundleItemsOptions

  useEffect(() => {
    const setOptions = async () => {
      await dispatch(userActions.setSelectedOptions(options))
    }

    setOptions()
  }, [dispatch, options, product])

  const seoRichSnippets = data?.products?.items[0]?.seo_rich_snippets

  const breadcrumbCategoryId = useMemo(
    () => getBreadcrumbCategoryId(product.categories),
    [product.categories]
  )

  return {
    product,
    breadcrumbCategoryId,
    seoRichSnippets
  }
}
