import styled from '@emotion/styled'

import { Breadcrumbs } from '@headless/ui'

export const StyledBreadcrumbs = styled(Breadcrumbs)({
  '&': {
    marginTop: '1rem',
    marginBottom: '1rem'
  },
  '& .MuiBreadcrumbs-separator': {
    position: 'relative',
    margin: '0 0.75rem'
  },
  '& .MuiBreadcrumbs-separator::after': {
    position: 'absolute',
    top: '50%',
    right: '-0.1rem',
    marginTop: '-0.125rem',
    content: '""',
    display: 'inline-block',
    width: '0.25rem',
    height: '0.25rem',
    backgroundColor: '#999',
    borderRadius: '50%'
  },
  '& .MuiLink-root': {
    fontSize: '0.75rem',
    color: '#222',
    textTransform: 'uppercase'
  },
  '& .MuiBreadcrumbs-li > span': {
    fontSize: '0.75rem',
    color: '#222',
    textTransform: 'uppercase'
  }
})
