import { WebsiteConf } from '@config/website'

export const getModeFromSearch = (url: string) => {
  const params = new URLSearchParams(url)
  const modeMap = new Map()
  const {
    category: { mode }
  } = WebsiteConf
  const { MODE_KEY, modeCode } = mode
  const modeState = params.has(MODE_KEY) ? params.get(MODE_KEY) : modeCode
  modeMap.set(MODE_KEY, modeState)

  return modeMap
}
