import styled from '@emotion/styled'

export const StyleSuccessMessage = styled.div`
  text-align: left;
  color: green;
`

export const StyleErrorMessage = styled.div`
  text-align: left;
  color: red;
`
