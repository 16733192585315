import { DocumentNode, gql } from '@apollo/client'

import { configurableProduct } from '../fragment/configurableProduct'
import { price_range } from '../fragment/priceRange'
import { price_tiers } from '../fragment/priceTiers'

export const GET_PRODUCT_DETAIL: DocumentNode = gql`
  query getProductDetail($filter: ProductAttributeFilterInput) {
    products(filter: $filter) {
      items {
        id
        sku
        name
        url_key
        uid
        seo_rich_snippets
        video_url
        main_image: image {
          label
          url
        }
        categories {
          uid
          breadcrumbs {
            category_uid
          }
        }
        meta_title
        meta_keyword
        meta_description
        price_range {
          ...price_range
          __typename
        }
        price_tiers {
          ...price_tiers
          __typename
        }
        stock_status
        description {
          html
        }
        short_description {
          html
        }
        presale_product {
          status
          label
          note
        }
        media_gallery {
          disabled
          label
          position
          thumbnail: url(type: "thumbnail", width: 120, height: 160)
          image: url(type: "image", width: 1125, height: 1500)
          # thumbnail: url(width: 1125, height: 1500)
          ... on ProductVideo {
            video_content {
              media_type
              video_provider
              video_url
              video_title
              video_description
              video_metadata
            }
          }
          __typename
        }
        shipping_policy {
          content
        }
        show_attribute {
          code
          label
          value
        }
        review_count
        rating_summary
        ...configurableProduct
        ... on BundleProduct {
          dynamic_sku
          dynamic_price
          dynamic_weight
          price_view
          ship_bundle_items
          prices {
            finalPrice {
              currency
              value
            }
          }
          items {
            option_id
            title
            sku
            uid
            type
            required
            position
            options {
              id
              uid
              label
              quantity
              can_change_quantity
              price
              price_type
              is_default
              position
              product {
                id
                name
                sku
                uid
                stock_status
                __typename
              }
            }
          }
        }
        __typename
      }
    }
  }
  ${price_range}
  ${price_tiers}
  ${configurableProduct}
`
