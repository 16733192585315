import styled from '@emotion/styled'

export const StyledNotify = styled.form`
  width: 43.75rem;
  padding: 0.625rem;

  .form__checkbox {
    display: none;
  }

  .MuiDialogTitle-root {
    > span {
      display: block;
      font-family: 'serif';
      font-size: 2.25rem;
      color: #333;
      text-align: center;
      text-transform: uppercase;
    }
  }

  .MuiDialogContent-root {
    padding-top: 0.5rem;

    > p {
      font-size: 0.875rem;
      color: #666;
      text-align: left;
      margin-bottom: 2rem;
    }

    .form {
      &__action {
        margin-bottom: 1rem;
      }

      &__checkbox {
        .MuiTypography-root {
          color: #240f0f;
          font-size: 0.75rem;
        }
      }

      &__item {
        display: grid;
        grid-auto-flow: column;
        grid-column-gap: 1.25rem;
      }
    }

    .MuiButton-root {
      padding: 0.875rem 1.25rem;
      font-size: 1.125rem;
      color: #222;
      font-weight: 500;
      background: #f2e0d6;
      border: 1px solid #f2e0d6;
      border-radius: 0;

      &:hover {
        background-color: #fff;
        border-color: #222;
      }
    }
  }

  .icon {
    width: 2.5rem;
    height: 2.5rem;
    display: block;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: cover;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    padding: 0;

    .MuiDialogTitle-root {
      padding: 1.5rem 0.9357rem;

      > span {
        font-size: 1.5rem;
        text-align: left;
      }
    }

    .MuiDialogContent-root {
      padding: 0 0.9375rem;

      .form__item {
        margin-top: 0.5rem;
        grid-auto-flow: row;
      }

      .MuiButton-root {
        margin-bottom: 1.5rem;
        padding: 0.75rem;
        font-size: 1rem;
      }
    }
  }
`
