import { gql } from '@apollo/client'

export const categoryTree = gql`
  fragment categoryTree on CategoryTree {
    id
    name
    url_path
    url_key
    meta_title
    meta_keywords
    meta_description
    seo_rich_snippets
    description
    uid
    adv(currentPage: $currentPage) {
      id
      title
      img
      link
      page
      position
    }
    breadcrumbs {
      category_id
      category_name
      category_url_path
      category_url_key
    }
  }
`
