import { FC, forwardRef } from 'react'

import TextField, { TextFieldProps } from '@mui/material/TextField'

const HeadlessTextField: FC<TextFieldProps> = forwardRef(
  ({ ...props }, ref) => {
    return <TextField ref={ref} {...props} />
  }
)

HeadlessTextField.displayName = 'TextField'

export default HeadlessTextField
