import styled from '@emotion/styled'

import { Button } from '@headless/ui'

export const StyledFilterButton = styled(Button)({
  '&': {
    padding: '0.25rem 0',
    color: '#999',
    textTransform: 'capitalize'
  },
  '& .MuiButton-endIcon': {
    height: '30px'
  },
  '&:hover': {
    backgroundColor: 'transparent'
  }
})
