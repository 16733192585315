import { FC, memo, useMemo } from 'react'

import CurrentFilter from '../CurrentFilter'
import { StyledCurrentFilter, StyledCurrentFilters } from './styled'

interface CurrentFiltersProps {
  filtersData: any
  filterState: any
  filterApi: any
  setIsApplying: Function
}

const CurrentFilters: FC<CurrentFiltersProps> = ({
  filtersData,
  filterState = new Map(),
  filterApi,
  setIsApplying
}) => {
  const { removeItem } = filterApi

  const filterElements = useMemo(() => {
    const elements = []
    for (const [group, items] of filterState) {
      const filterItem = filtersData.find((filter: any) => {
        return filter.attribute_code === group
      })

      for (const item of items) {
        const { label, value } = item || {}
        const key = `${group}-${label}-${value}`

        elements.push(
          <StyledCurrentFilter key={key}>
            <CurrentFilter
              group={group}
              label={filterItem.label}
              item={item}
              removeItem={removeItem}
              setIsApplying={setIsApplying}
            />
          </StyledCurrentFilter>
        )
      }
    }

    return elements
  }, [filterState, filtersData, removeItem, setIsApplying])

  return <StyledCurrentFilters>{filterElements}</StyledCurrentFilters>
}

export default memo(CurrentFilters)
