import styled from '@emotion/styled'

import { Button } from '@headless/ui'

export const StyledFilterItem = styled.div`
  margin-bottom: 0.75rem;

  .swatch {
    display: block;
    width: 1.5625rem;
    height: 1.5625rem;
    margin-right: 0.5rem;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 50%;
  }

  .MuiButton-root {
    span {
      font-size: 0.875rem;
      text-transform: capitalize;
      text-align: left;
    }
  }

  .MuiLink-root {
    color: #615956;
    display: flex;
    padding: 0.5rem 0.9375rem 0.5rem 0.9375rem;
    text-decoration: none;
    border: 1px solid #d6d0cb;
    justify-content: flex-start;
    align-items: center;

    span {
      font-size: 0.875rem;
      text-transform: capitalize;
    }

    &:hover {
      border-color: #000;
    }
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 0;

    .MuiLink-root {
      padding: 0.9375rem;
      border-bottom: 0;

      span {
        font-size: 1rem;
      }
    }
  }
`

export const StyledCategoryItem = styled.div`
  margin-bottom: 10px !important;
  cursor: pointer;
  display: inline-block;

  &:hover {
    text-decoration: underline;
  }
  a {
    color: #615956;
    text-decoration: none;
  }
`

export const StyledFilterButton = styled(Button)({
  '&': {
    width: '100%',
    color: '#615956',
    fontSize: '0.875rem',
    borderRadius: 'unset',
    borderColor: '#d6d0cb'
  },
  '&.MuiButton-containedSuccess': {
    color: '#fff'
  }
})
