import { FC, memo } from 'react'

import useMediaQuery from '@mui/material/useMediaQuery'
import { StyledProductRating, StyledRating } from './styled'

interface ProductReviewProps {
  count: number
  summary: number
  onReviewClick?: () => void
  showDivider?: boolean
  isShowScore?: boolean
}

const ProductReview: FC<ProductReviewProps> = ({
  count,
  summary,
  onReviewClick,
  showDivider = false,
  isShowScore = true
}) => {
  const maybeDivider = showDivider ? <div>|</div> : null

  let newSummary = summary

  if (summary < 100 && summary > 90) {
    newSummary = 90
  }

  const isMobile: boolean = useMediaQuery('(max-width:767px)')

  return (
    <StyledProductRating className="product__reivews" onClick={onReviewClick}>
      <StyledRating value={(newSummary * 5) / 100} precision={0.1} readOnly />
      {maybeDivider}
      {/* <span>{(summary * 5) / 100}</span> */}
      {/* 保留一位小数并直接舍去。注意乘以10和除以10的操作，这是为了将小数位数提前到整数位，然后再进行舍去操作，最后再将结果转换回小数形式 */}
      {isShowScore && (
        <span>{(Math.floor(((summary * 5) / 100) * 10) / 10).toFixed(1)}</span>
      )}
      {isMobile ? (
        <span className="count">
          <span>{`(${count})`}</span>
        </span>
      ) : (
        <span className="count">
          <span>{count}</span>
          <span className="label">{` ${
            count > 1 ? 'Reviews' : 'Review'
          }`}</span>
        </span>
      )}
    </StyledProductRating>
  )
}

export default memo(ProductReview)
