import { useMemo } from 'react'

interface ProductPriceProps {
  minimum_price: any
  maximum_price: any
}

export const useProductPrice = ({
  minimum_price,
  maximum_price
}: ProductPriceProps) => {
  const hasGroup = useMemo(() => {
    return maximum_price.final_price.value === minimum_price.final_price.value
  }, [maximum_price, minimum_price])

  const hasSpecial = useMemo(() => {
    return minimum_price.final_price.value < minimum_price.regular_price.value
  }, [minimum_price])

  const unitPrice = useMemo(() => {
    if (hasGroup) {
      if (hasSpecial) {
        return minimum_price.final_price.value
      }

      return minimum_price.regular_price.value
    }

    return minimum_price.final_price.value
  }, [hasGroup, hasSpecial, minimum_price])

  return { hasGroup, hasSpecial, unitPrice }
}
